const getters = {
    reparTipoDes (state) {
        if (state.dicReparCod.repar_tipo === 'C') {
            return 'Retira de un punto de entrega'
        }
        if (state.dicReparCod.repar_tipo === 'D') {
            return 'Envio a domicilio'
        }
        return '??????'
    },


    reparDircDes (state) {
        if (state.dicReparCod.repar_tipo === 'C') {
            return state.dicReparCod.des
        }
        if (state.dicReparCod.repar_tipo === 'D' && !state.dicReparData.repar_loc) {
            return 'Idem a la dirección de facturación'
        }
        return ''
    },

    tituloSearch (state) {
        if (state.optionsPlu.length) {
            return `Resultados para: "${state.pluSearch}"`
        } else {
            return `No se han encontrado resultados para: "${state.pluSearch}"`
        }
    }

}

export default getters
