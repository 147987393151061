import { evAxio } from "@/evlib/utils/ev-axios";
import { evRound } from "@/evlib/utils/ev-modelo";

import {
  parserWebLstPrs,
  sumLstPrsCant,
  updateLstPrsCant,
} from "@/utils/prs-cant";

const actions = {
  // Acciones que cambian el estadio del cmp
  // ====================================================================v
  goDepoSelecting({ commit }) {
    commit("SET_CMP_ESTADIO", "depoSelecting");
  },

  goMainSearching({ commit }) {
    commit("SET_CMP_ESTADIO", "mainEditing");
  },

  goRenglonInserting({ commit }) {
    commit("SET_CMP_ESTADIO", "renglonInserting");
  },
  goRenglonEditing({ commit }) {
    commit("SET_CMP_ESTADIO", "renglonEditing");
  },
  // ====================================================================^

  // Se cargan una unica vez al inicio
  // ====================================================================v
  initOptionsPrs({ commit }, vm) {
    evAxio({ vm, url: "/ccd_cliente/tienda/api/optionsPrs" }).then((data) => {
      commit("SET_OPTIONS_PRS", data.options);
    });
  },

  initOptionsGrpgrpdep({ commit }, vm) {
    evAxio({ vm, url: "/ccd_cliente/tienda/api/optionsGrpgrpdep" }).then(
      (data) => {
        commit("SET_OPTIONS_GRPGRPDEP", data.options);
      }
    );
  },

  initOptionsGrpdep({ commit }, vm) {
    evAxio({ vm, url: "/ccd_cliente/tienda/api/optionsGrpdep" }).then(
      (data) => {
        commit("SET_OPTIONS_GRPDEP", data.options);
      }
    );
  },

  initOptionsDep({ commit }, vm) {
    evAxio({ vm, url: "/ccd_cliente/tienda/api/optionsDep" }).then((data) => {
      commit("SET_OPTIONS_DEP", data.options);
    });
  },

  initOptionsReparCod({ commit }, vm) {
    evAxio({ vm, url: "/ccd_cliente/tienda/api/optionsReparCod" }).then(
      (data) => {
        commit("SET_REPAR_DEPO_CANT", data.repar_depo_cant);
        commit("SET_OPTIONS_REPAR_COD", data.options);
        if (data.repar_depo_cant === 1) {
          commit("SET_DIC_REPAR_COD", data.options[0]);
        }
      }
    );
  },
  // ====================================================================^

  getTiendaConf({ commit, state }, vm) {
    evAxio({
      vm,
      url: "/ccd_cliente/tienda/api/getTiendaConf",
      params: { lst_depo: state.dicReparCod.lst_depo },
    }).then((data) => {
      commit("SET_TIENDA_CONF", data.tiendaConf);
    });
  },

  // Se llama cada vez que cambian las condiciones de busqueda
  // =====================================================================
  // pluOptionsParams  = { grpgrpdep, grpdep, dep, mar, search }
  refreshOptionsPlu(
    { commit, state },
    { vm, grpgrpdep, grpdep, dep, mar, search }
  ) {
    return new Promise((resolve, reject) => {
      evAxio({
        vm,
        url: "/ccd_cliente/tienda/api/optionsPlu",
        params: {
          grpgrpdep,
          grpdep,
          dep,
          mar,
          search,
          lst_depo: state.dicReparCod.lst_depo,
        },
      })
        .then((data) => {
          commit("SET_OPTIONS_PLU", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Refresca state.dicPlu
  // =====================================================================
  async refreshDicPlu({ commit, state }, { vm, cod }) {
    // console.log(['refreshDicPlu-1:  cod=', cod])
    return new Promise((resolve, reject) => {
      commit("SET_DIC_PLU", {});
      evAxio({
        vm,
        url: "/ccd_cliente/tienda/api/pluInfo",
        params: {
          cod,
          lst_depo: state.dicReparCod.lst_depo,
        },
        nvlError: 0,
      })
        .then((data) => {
          // console.log(['refreshDicPlu-2:  data.dic_plu.cod=', data.dic_plu.cod])

          commit("SET_DIC_PLU", data.dic_plu);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // =====================================================================
  async cmdSelectPlu({ commit, state, dispatch }, { vm, cod }) {
    if (state.tiendaConf.fpg_cta === "S" && !vm.$root.evUser) {
      // Debe estar logueado
      // console.log(['Debe estar logueado: to.fullPath=', to.fullPath])
      vm.$router.replace({
        path: "/login",
        query: { nextUrl: "/" },
      });
      return;
    }
    if (!state.dicReparCod.repar_cod) {
      if (state.reparDepoCant !== 1) {
        dispatch("goDepoSelecting");
        return;
      }
      commit("SET_DIC_REPAR_COD", state.optionsReparCod[0]);
    }

    const index = state.dicPedido.renglones.findIndex((dicRenglon) => {
      return dicRenglon.cod === cod;
    });

    if (index > -1) {
      dispatch("cmdRenglonEditar", { vm, index });
      dispatch("goRenglonEditing");
      return;
    }
    await dispatch("cmdRenglonAgregar", { vm, cod });
    dispatch("goRenglonInserting");
  },

  // =====================================================================
  async cmdRenglonAgregar({ commit, state, dispatch }, { vm, cod }) {
    // console.log(['cmdRenglonAgregar'])

    if (cod !== state.dicPlu.cod) {
      await dispatch("refreshDicPlu", { vm, cod });
    }

    const dicRenglon = {
      cod,
      cant: 1,
      lstPrsCant: [],
    };

    if (state.dicPlu.web_lst_prs) {
      dicRenglon.cant = 0;

      // lstPrsCant = [{prs, des, web_coef, web_coef_calc, prs_cant, cant},..]
      dicRenglon.lstPrsCant = parserWebLstPrs(
        state.dicPlu.web_lst_prs,
        state.optionsPrs
      );
    }

    // console.log(['cmdRenglonAgregar.dicRenglon=', dicRenglon])

    commit("SET_NRO_RENGLON_EDITING", null);
    commit("SET_DIC_RENGLON_EDITING", dicRenglon);
  },

  // =====================================================================
  cmdRenglonEditar({ commit, state }, { index }) {
    const dicRenglon = state.dicPedido.renglones[index];

    commit("SET_NRO_RENGLON_EDITING", index);
    commit("SET_DIC_RENGLON_EDITING", dicRenglon);
  },

  // Actualiza un renglon
  // =====================================================================
  cmdRenglonUpdate(
    { commit, state },
    {
      cod,
      web_des,
      prec_m,
      precbul_m,
      stat_m,
      stat_z,
      cant,
      file_ni,
      stkdispo,
      lstPrsCant,
    }
  ) {
    // console.log(['cmdRenglonUpdate: cod=', cod])
    const dicRenglon = {
      cod,
      web_des,
      prec_m,
      precbul_m,
      stat_m,
      stat_z,
      cant,
      file_ni,
      stkdispo,
      imp_m: evRound((stat_m && cant >= stat_m ? precbul_m : prec_m) * cant, 2),
      lstPrsCant: lstPrsCant || [],
    };
    const i = state.dicPedido.renglones.findIndex((renglon) => {
      return renglon.cod === cod;
    });

    if (i < 0) {
      if (cant > 0) {
        // Append
        state.dicPedido.renglones.push(dicRenglon);
      }
    } else {
      if (cant > 0) {
        // Update
        state.dicPedido.renglones[i] = dicRenglon;
      } else {
        // Detele
        state.dicPedido.renglones.splice(i, 1);
      }
    }

    commit("SET_DIC_PEDIDO", { ...state.dicPedido });
  },

  // Actualiza un renglon
  // =====================================================================
  cmdVaciarCarrito({ commit }) {
    commit("SET_DIC_PEDIDO", {});
    commit("SET_DIC_REPAR_COD", {});
  },

  refreshRenglones({ commit, state, dispatch }, { vm, renglones }) {
    // eslint-disable-next-line no-console
    // console.log(['refreshRenglones(), renglones=', renglones])

    commit("SET_DIC_PEDIDO", {});

    renglones.forEach(async (renglon) => {
      await dispatch("refreshDicPlu", { vm, cod: renglon.cod });

      let cant = 0;
      let lstPrsCant = [];

      if (state.dicPlu.web_lst_prs) {
        if ((renglon.lstPrsCant || []).length) {
          // Obtiene una nueva newLstPrsCant desde state.dicPlu.web_lst_prs
          // y actualiza newLstPrsCant.prs_cant desde renglon.lstPrsCant.prs_cant
          lstPrsCant = updateLstPrsCant(
            parserWebLstPrs(state.dicPlu.web_lst_prs, state.optionsPrs),
            renglon.lstPrsCant,
            state.dicPlu.peso
          );

          // Sumariza para obtener el total equivalente en Kg
          cant = sumLstPrsCant(lstPrsCant);
        }
      } else {
        if (!(renglon.lstPrsCant || []).length) {
          cant = renglon.cant;
        }
      }

      if (cant) {
        dispatch("cmdRenglonUpdate", {
          ...state.dicPlu,
          cant,
          lstPrsCant,
        });
      }
    });
  },

  // 08-03-2021: Borrar
  /* 
  refreshRenglonesOLD ({ commit, state }, { vm, renglones }) {
    // eslint-disable-next-line no-console
    // console.log(['refreshRenglones(), renglones=', renglones])

    commit("SET_DIC_PEDIDO", {})
    evAxio(
      {
        vm,
        url: '/ccd_cliente/tienda/api/refreshRenglones',
        params: { renglones }
      }
    )
      .then((data) => {
        state.dicPedido.renglones = data.renglones
        commit('SET_DIC_PEDIDO', { ...state.dicPedido })
      })
  }
  */
};

export default actions;
