<template>
  <v-container>

    <v-list-item
      three-line
      v-show="$store.state.pluSearch"
    >
      <v-list-item-content>
        <v-list-item-subtitle class="primary--text">
          {{ tituloSearch }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <plu-menu-path v-show="!$store.state.pluSearch" />

    <v-card class="my-2">
      <plu-menu-grpdep
        v-for="grpdep in $store.state.optionsPluGrpdep"
        :key="grpdep.grpdep"
        :grpdep="grpdep.grpdep"
        :des="grpdep.des"
      ></plu-menu-grpdep>
    </v-card>

    <v-card
      class="my-2"
      v-show="this.$store.state.optionsPluMar.length"
    >
      <plu-menu-mar></plu-menu-mar>
    </v-card>

  </v-container>
</template>

<script>
import pluMenuGrpdep from '@/components/plu-menu-grpdep'
import pluMenuMar from '@/components/plu-menu-mar'
import pluMenuPath from '@/components/plu-menu-path'
export default {
  name: 'plu-menu',
  components: {
    pluMenuGrpdep,
    pluMenuMar,
    pluMenuPath
  },
  computed: {
    tituloSearch () {
      return this.$store.getters.tituloSearch
    }
  }
}
</script>
