/* ev-form.js
 *  Ruben Valle
 *  Ver. 1.0: 24-09-2022
 *  ==========================================================
 */

import evAxios from "@/evlib/utils/ev-axios3";
import { evGetModelos } from "@/evlib/utils/ev-tables";
import { evParser, evFormatter, evVldModelo } from "@/evlib/utils/ev-modelo";

/** * * * * * * * * * * * * * * * * * * *
 * EvForm():
 * Class para validar formularios.
 * * * * * * * * * * * * * * * * * * * * *
 *
 * Llamando a: form = new EvForm(modelos)
 *
 * Obtenemos:
 * ---------------------
 * form: {
 *   // ------------------v
 *   keys: [],     // Lista de la keys de c/u de los campos.
 *   modelos: {},  // Metainformacion de los campos.
 *   data: {},     // Data de los campos.
 *   values: {},   // evFormatter(data) sobre lo que se edita dediante v-model.
 *   errors: {}    // Error de los campos.
 *     null: Aun no validado.
 *     '': Ya validado, sin error.
 *     String: Ya validado, con error.
 *   // ------------------^
 *
 *   // Para facilitar la validacion asincronica de campos.
 *   // ------------------v
 *   vldsLoading: {},
 *   vldsData: {},
 *   vldsError: {},
 *     null: Aun no validado.
 *     '': Ya validado, sin error.
 *     String: Ya validado, con error.
 *   // ------------------^
 *
 *  // Metodos
 *  // ------------------v
 *  vldBegin (field): Inicia la validacion asincronica de un campo.
 *  vldEnd (field, vldData, vldError): Finaliza la validacion asincronica de un campo.
 *
 *  fieldSetData (key, d):
 *  fieldSetError (key, e):
 *  fieldResetData (key, d):
 *  fieldVldModelo (key): // Valida el campo en base a la infromacion del modelo.
 *
 *  // Retorna el componente que corresponde usar
 *  // en base a la metainformacion del modelo.
 *  fieldComponent (key)
 *
 *  findError ():
 *     Retorma el "key" del primer campo con error.
 *
 *  formVld ():
 *    Valida todo el "data" completo.
 *    Retorma true si paso la validacion de todos los campos.
 *
 *  formClear ():
 *  formReset ():
 *  formResetErrors ():
 *
 *  formSetDatas (datas):
 *  formSetErrors (errors):
 *   // ------------------^
 *
 * }
 */
// ==================================================================

export class EvForm {
  ok = false; // Indica si ya fue inicializado y esta disponible para ser rederizado.

  vm = null; // Se inicializa en initAll()

  url = null; // URL para apis. Ej: '/demovue/vudemo3/api/locEditRow2'
  title = ""; // Titulo de la pantalla principal
  subtitle = ""; // Subtitulo

  submiting = false; // Indica que se esta comunicando con el server

  nextFocus = null; // Indica el proximo field que debe tomar focus

  keys = [];
  data = {};
  values = {};
  errors = {};
  vldsLoading = {};
  vldsData = {};
  vldsError = {};

  constructor({ url, title, subtitle }) {
    this.vm = null; // Se inicializa en initAll({vm}))
    this.url = url; // URL para apis. Ej: '/demovue/vudemo3/api/locEditRow2'
    this.title = title || ""; // Titulo de la pantalla principal
    this.subtitle = subtitle || ""; // Subtitulo
  }

  // Incializa
  // ================================================================v
  async initAll({ vm, modelos, urlModelos }) {
    this.vm = vm; // Guarda puntero al componente Vue

    if (!modelos) {
      modelos = await this.initGetModelos(urlModelos);
    }
    if (modelos) {
      this.initModelos(modelos);
      this.ok = true; // Indica que ya puede ser renderizado.
    }
  }

  async initGetModelos(urlModelos) {
    this.submiting = true;
    const data = await evAxios.post(
      urlModelos || `${this.url}/getParamFields/cmdProcesar`,
      {
        _token: this.vm.$root.evToken,
      }
    );
    this.submiting = false;
    if (data.error_nro !== 0) {
      await this.vm.$root.$evModal.showError(data.error_txt);
      return;
    }

    // Excluimos: '_flt_data, _usr_join, _usr_where, _etc'
    const modelos = evGetModelos(data.fields.filter((v) => v.name[0] !== "_"));
    return modelos;
  }

  initModelos(modelos) {
    this.modelos = modelos;

    this.keys = Object.keys(this.modelos);

    this.data = this.keys.reduce((obj, key) => {
      obj[key] = null;
      return obj;
    }, {});

    this.values = this.keys.reduce((obj, key) => {
      obj[key] = "";
      return obj;
    }, {});

    this.errors = this.keys.reduce((obj, key) => {
      obj[key] = null;
      return obj;
    }, {});

    this.vldsLoading = this.keys.reduce((obj, key) => {
      obj[key] = false;
      return obj;
    }, {});

    this.vldsData = this.keys.reduce((obj, key) => {
      obj[key] = {};
      return obj;
    }, {});

    this.vldsError = this.keys.reduce((obj, key) => {
      obj[key] = null;
      return obj;
    }, {});
  }
  // ================================================================^

  // ================================================================v

  // Retorna el componente que corresponderia usar
  // en base a la metainformacion del modelo.
  fieldComponent(key) {
    const modelo = this.modelos[key];
    return (
      modelo.component ||
      ("options" in modelo ? "evu-field-select" : "") ||
      (modelo.view_heigth || modelo.type === "evJS"
        ? "evu-field-textarea"
        : "") ||
      ("lov" in modelo ? "evu-field-lov" : "") ||
      "evu-field-text"
    );
  }

  fieldSetData(key, d) {
    // console.log(['fielSetdata() key=', key, 'd=', d])
    // Con evParser nos aseguramos que se guarde con el type que le corresponda
    this.data[key] = evParser(d, this.modelos[key]);
    // console.log(['fielSetdata() key=', key, 'd=', d, 'this.data[key]=', this.data[key]])

    // Con evFormatter nos aseguramos que se edite en el formato que corresponda.
    this.values[key] = evFormatter(this.data[key], this.modelos[key]);
    this.fieldSetError(key, null);
  }

  fieldSetError(key, e) {
    this.errors[key] = e;
  }

  fieldResetData(key) {
    this.fieldSetData(key, this.modelos[key].default);
  }

  fieldVldModelo(key) {
    this.fieldSetError(key, evVldModelo(this.data[key], this.modelos[key]));
    return !this.errors[key];
  }

  // Retorma el "key" del primer campo con error
  findError() {
    return this.keys.find((key) => !!this.errors[key]);
  }

  fieldFocus(key) {
    // El componente es el encargado de reaccionar
    this.nextFocus = key;
  }

  // ================================================================v
  onInputField(key, v) {
    this.values[key] = v;
    this.errors[key] = null;
    this.vldsError[key] = null;
  }

  // ================================================================^

  // Field-Des
  // Ej:
  // vldDes={
  //  'url': '/gra/public/api/vldDes/gra_prov/prov',
  //  'fieldDes': 'prov_des',
  //  }
  // ================================================================v
  async onChangeVldDes(key) {
    let vldData, vldError;

    const vldDes = this.modelos[key].vldDes;
    if (vldDes.fieldDes) {
      // Reseteo campo vinculado
      this.fieldSetData(vldDes.fieldDes, null);
    }
    this.vldBegin(key);

    if (this.data[key] !== null) {
      const params = {
        _token: this.vm.$root.evToken,
      };

      // Ej: params['prov'] = 24
      params[vldDes.url.split("/").reverse()[0]] = this.data[key];

      if (vldDes.paramsKeys) {
        // vldDes.paramsKeys.forEach((k) => (params[k] = this.data[k]));
        for (const k of vldDes.paramsKeys) {
          params[k] = this.data[k];
        }
      }

      const data = await evAxios.post(vldDes.url, params);
      if (data.error_nro === 0) {
        // Guardo resultado de la validacion
        vldData = data.dicData;
        if (vldDes.fieldDes) {
          // Seteo campo vinculado
          // console.log(['vldData.text=', vldData.text, 'fieldDes=', fieldDes, 'form=', form])
          this.fieldSetData(vldDes.fieldDes, vldData.text);
        }
      } else {
        vldError = data.error_txt;
      }
    }
    this.vldEnd(key, vldData, vldError);
  }
  // ================================================================^

  // ================================================================v
  async onChangeVldPk(key) {
    let vldData, vldError;

    const vldPk = this.modelos[key].vldPk;
    this.vldBegin(key);

    if (this.data[key] !== null) {
      const params = {};

      // Ej: params['prov'] = 24
      params[vldPk.url.split("/").reverse()[0]] = this.data[key];

      if (vldPk.paramsKeys) {
        // vldDes.paramsKeys.forEach((k) => (params[k] = this.data[k]));
        for (const k of vldPk.paramsKeys) {
          params[k] = this.data[k];
        }
      }

      const data = await evAxios.post(vldPk.url, params);
      if (data.error_nro) {
        this.fieldSetError(key, data.error_txt);
        vldError = data.error_txt;
      }
      this.vldEnd(key, vldData, vldError);
    }
  }
  // ================================================================^

  // ================================================================v

  // Pone el foco en el primer campo con error
  errorFocus() {
    const keyError = this.findError();
    // debugger
    if (keyError) {
      this.fieldFocus(keyError);
    }
  }
  formFocus() {
    this.fieldFocus(this.keys[0]);
  }

  // Valida todo el "data" completo.
  // Retorma true si paso la validacion de todos los campos.
  formVld() {
    // 20-12-2021
    // En vuetify aconsejan usar for...in o for...of en luar de forEach()
    /*
            this.keys.forEach(
                (key) => {
                    // Antes de validar me aseguro de sincronizar this.data[key] con this.values[key]
                    this.fieldSetData(key, this.values[key])
                    this.fieldVldModelo(key)
                }
            )
            */
    for (const key of this.keys) {
      // Antes de validar me aseguro de sincronizar this.data[key] con this.values[key]
      this.fieldSetData(key, this.values[key]);
      this.fieldVldModelo(key);
    }
    return !this.findError();
  }

  // Me aseguro que todos los fieldVldDes y filedVldPk esten validados.
  async formVldDes() {
    for (const key of this.keys) {
      if (this.modelos[key].vldPk && this.vldsError[key] === null) {
        await this.onChangeVldPk(key);
      }
      if (this.modelos[key].vldDes && this.vldsError[key] === null) {
        await this.onChangeVldDes(key);
      }
      if (this.vldsError[key]) {
        this.fieldSetError(key, this.vldsError[key]);
        return false;
      }
    }
    return true;
  }

  async formVldAll() {
    let ok = true;
    this.submiting = true;

    if (!this.formVld() || !(await this.formVldDes())) {
      this.errorFocus();
      ok = false;
    }
    this.submiting = false;
    return ok;
  }

  formClear() {
    for (const key of this.keys) {
      this.fieldSetData(key, null);
    }
  }

  formReset() {
    for (const key of this.keys) {
      this.fieldResetData(key);
    }
  }

  formSetDatas(datas) {
    for (const key of this.keys) {
      if (key in datas) {
        this.fieldSetData(key, datas[key]);
      }
    }
  }

  formResetErrors() {
    for (const key of this.keys) {
      this.fieldSetError(key, null);
    }
  }

  formSetErrors(errors) {
    for (const key of this.keys) {
      if (key in errors) {
        this.fieldSetError(key, errors[key]);
      }
    }
  }

  // ================================================================^
  // ================================================================v

  vldBegin(field) {
    this.vldsLoading[field] = true;
    this.vldsData[field] = {};
    this.vldsError[field] = null;
  }

  vldEnd(field, vldData, vldError) {
    this.vldsLoading[field] = false;
    this.vldsData[field] = vldData || {};
    this.vldsError[field] = vldError || "";
  }
  // ================================================================^
}
