<template>
  <div>
    <fin-resumen></fin-resumen>

    <v-container class="text-center">
      <v-textarea
        clearable
        :counter="200"
        :maxlength="200"
        outlined
        v-model="aclaracion"
        label="Aclaración:"
        placeholder="Si lo desea puede ingresar una aclaración de su pedido."
        autofocus
      >
      </v-textarea>


      <v-card color="grey lighten-4">
      <v-container>
        <p>{{ vmApp.sustMsg }}</p>
        <v-radio-group 
          column 
           v-model="sustituir"
        >
          <template v-slot:label>
            <div>
              Seleccione el criterio de sustitución que prefiere:
            </div>
            </template>
          <template v-for="option in vmApp.sustOptions">
            <v-radio
              :key="option.cod"
              :label="option.des"
              :value="option.des"
            />
          </template> 
        </v-radio-group>
      </v-container>

    </v-card>


      <v-btn
        class="mt-2"
        @click="continuar"
        rounded
        color="success"
        :loading="loading"
        :disabled="!sustituir"
      >Confirmar Compra</v-btn>
    </v-container>
  </div>
</template>

<script>
import finResumen from "@/components/fin-resumen"

export default {
  name: "fin-confirmar",
  components: {
    finResumen
  },
  props: {
    loading: { type: Boolean }
  },
  inject: ["vmApp"],
  data () {
    return {
      aclaracion: null,
      sustituir: null,
    }
  },
  computed: {
    logoSrc () {
      return this.vmApp.appBarConf.dark ? '/static/local/ccd_cliente/assets/logonavbar-dark.svg' : '/static/local/ccd_cliente/assets/logonavbar.svg'
    },

  },
  methods: {
    continuar () {
      this.$emit(
        'input', 
        {
          'aclaracion': this.aclaracion,
          'sustituir': this.sustituir,
        }
      )
    }
  }
}
</script>
