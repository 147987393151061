<template>
  <div></div>
</template>


<script>
import { evAxio } from '@/evlib/utils/ev-axios'

import { evSessionRemove } from '@/evlib/utils/ev-session'
export default {
  name: 'ev-logout',
  inject: [
    'vmApp'
  ],
  methods: {
    logoutAppUser () {
      this.$root.$evModal.showWait('Saliendo...')
      // const url = `/${this.vmApp.$root.evSessionId}/login/api/logoutAppUser`
      const url = `/${this.vmApp.$root.evSessionId}/login/api/logout`
      evAxio({
        vm: this,
        url: url,
        nvlError: 0
      })
        .then(() => {
          this.sessionRemove()
        })
        .finally(() => { this.$root.$evModal.hideWait() })
    },

    sessionRemove () {
      evSessionRemove(this.vmApp)
      this.$router.replace('/')
    }

  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      // Cierra Session
      if (vm.$root.evSessionType) {
        vm.sessionRemove()
      } else {
        vm.logoutAppUser()
      }
    })
  }
}
</script>
