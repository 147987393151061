<template>
  <div>
    <v-container>

      <v-list flat>
        <v-list-item-group
          v-model="webFcb"
          color="primary"
        >

          <template v-for="option in lstWebFcb">
            <v-list-item
              :key="option.web_fcb"
              :value="option.web_fcb"
            >

              <template v-slot:default="{ active }">
                <v-list-item-content>

                  <v-list-item-subtitle>
                    {{option.des}}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{option.k_cuo}} x $ {{numberFormat(option.imp_cuo)}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Total: $ {{numberFormat(option.tot)}}
                  </v-list-item-subtitle>

                  <v-alert
                    dense
                    type="warning"
                    v-show="active && !!option.adv_msg"
                  >
                    {{option.adv_msg}}
                  </v-alert>                    

                </v-list-item-content>

                <v-list-item-action-text>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action-text>
              </template>

            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>

      <v-container class="text-center">
        <v-btn
          @click="continuar"
          outlined
          rounded
          color="primary"
          :disabled="!webFcb"
          :loading="loading"
        >Continuar</v-btn>
      </v-container>
    </v-container>

  </div>
</template>

<script>
import { evNumberFormat } from "@/evlib/utils/ev-modelo"
export default {
  name: "fin-slc-web-fcb",
  props: {
    loading: { type: Boolean }
  },
  data () {
    return {
      webFcb: null
    }
  },
  computed: {
    lstWebFcb () {
      return this.$store.state.dicTot.lst_web_fcb || []
    },
  },
  methods: {
    continuar () {
      const dicWebFcb = this.lstWebFcb.find(v => v.web_fcb === this.webFcb) || {}
      this.$store.commit('SET_DIC_WEB_FCB', dicWebFcb)
      this.$emit('input')
    },
    numberFormat (n) {
      return evNumberFormat[2].format(n)
    }
  }
}
</script>
