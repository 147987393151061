<template>

  <v-container
    fluid
    v-if="lstPlu.length"
  >

    <v-toolbar flat>
      <v-spacer></v-spacer>

      <v-toolbar-title class="text-h6 grey--text text--darken-1"> {{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="idShow='card'"
        v-show="!$vuetify.breakpoint.smAndDown"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="idShow='list'"
        v-show="!$vuetify.breakpoint.smAndDown"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

    </v-toolbar>

    <!-- desktop.Begin -->
    <v-slide-group
      v-model="model"
      show-arrows
      v-if="!$vuetify.breakpoint.smAndDown && idShow !== 'list'"
    >
      <v-slide-item
        v-for="(plu, i) in lstPlu"
        :key="i"
        v-slot="{ toggle }"
      >
        <plu-card
          :dicPlu="plu"
          class="ma-2"
          style="width:250px;"
          @click="toggle"
        >
        </plu-card>

      </v-slide-item>
    </v-slide-group>
    <!-- desktop.End -->

    <!-- movil.Begin -->
    <v-list
      three-line
      v-if="$vuetify.breakpoint.smAndDown || idShow === 'list'"
    >
      <template v-for="(plu, i) in lstPlu">
        <plu-list-item
          :key="i"
          :dicPlu="plu"
        >
        </plu-list-item>
      </template>
    </v-list>
    <!-- movil.End -->

  </v-container>
</template>

<script>
import pluCard from "@/components/plu-card"
import pluListItem from "@/components/plu-list-item"
export default {
  name: "t-plu-slide",
  props: {
    title: { type: String },
    lstPlu: { type: Array, required: true } // [{}, ...]
  },
  data: () => ({
    model: null,
    idShow: 'card', // 'card', 'list'
  }),
  components: {
    pluCard,
    pluListItem
  },
}
</script>
