<template>
  <v-card>
    <v-subheader> Resumen de su compra:</v-subheader>

    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>Ver renglones...</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>

            <v-list-item
              v-for="(item, i) in $store.state.dicPedido.renglones"
              :key="i"
              three-line
            >
              <v-list-item-avatar>
                <v-img :src="item.file_ni ? '/drive/file/thumbnail/0/' + item.file_ni : ''"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-subtitle class="primary--text">
                  Cod: {{item.cod}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{item.web_des}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.cant }} x $ {{ numberFormat(item.prec_m)}}
                </v-list-item-subtitle>
                <v-list-item-title class="accent--text">
                  ${{numberFormat(item.imp_m)}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-list>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-subtitle>
            <div class="d-flex">
              Subtotal ({{renglones.length}} ítems): <span class="ml-auto">$ {{numberFormat(dicTot.tot_merca)}}</span>
            </div>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-show="dicTot.tot_envio">
            <div class="d-flex">
              Costo de Envío: <span class="ml-auto">$ {{numberFormat(dicTot.tot_envio)}}</span>
            </div>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-show="dicTot.tot_finan">
            <div class="d-flex">
              Financiación: <span class="ml-auto">$ {{numberFormat(dicTot.tot_finan)}}</span>
            </div>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-show="dicTot.tot_per">
            <div class="d-flex">
              Percepción / Impuestos: <span class="ml-auto">$ {{numberFormat(dicTot.tot_per)}}</span>
            </div>
          </v-list-item-subtitle>

          <v-list-item-title class="primary--text">
            <div class="d-flex">
              <span>Total a Pagar: </span><span class="ml-auto">$ {{numberFormat(dicTot.tot_final)}}</span>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            Su dirección de facturación:
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ dicCta.nom}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ dicCta.dirc}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{dicCta.cp }} - {{ dicCta.loc_des}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ dicCta.prov_des}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ dicCta.tel_area1}} - {{ dicCta.tel_nro1}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ dicCta.tel_area2}} - {{ dicCta.tel_nro2}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        two-line
        v-show="dicReparCod.repar_cod"
      >
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            Tipo de entrega de la mercadería:
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ reparTipoDes }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-show="dicReparCod.repar_cod && !dicReparData.repar_loc">
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            Dirección de entrega:
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ reparDircDes }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        three-line
        v-show="dicReparCod.repar_cod && dicReparData.repar_loc"
      >
        <v-list-item-content>
          <v-list-item-subtitle>
            <span class="text-subtitle-1 primary--text"> Su dirección de entrega: </span>{{ reparDircDes }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ dicReparData.repar_dirc}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{dicReparData.repar_cp }} - {{ dicReparData.repar_loc_des}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ dicReparData.repar_prov_des}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ dicReparData.repar_tel_area1}} - {{ dicReparData.repar_tel_nro1}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ dicReparData.repar_tel_area2}} - {{ dicReparData.repar_tel_nro2}}
          </v-list-item-subtitle>

        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        three-line
        v-show="dicWebFcb.web_fcb"
      >
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            Medio de pago:
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ dicWebFcb.des }}
          </v-list-item-subtitle>
          <v-list-item-title>
            <div class="d-flex">
              {{ dicWebFcb.k_cuo }} x $ {{ numberFormat(dicWebFcb.imp_cuo) }} <span class="ml-auto">$ {{numberFormat(dicTot.tot_final)}}</span>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-card>
</template>

<script>
import { evNumberFormat } from "@/evlib/utils/ev-modelo"
export default {
  name: "fin-resumen",
  computed: {
    dicCta () {
      return this.$store.state.dicCta
    },
    dicWebFcb () {
      return this.$store.state.dicWebFcb
    },
    dicReparCod () {
      return this.$store.state.dicReparCod
    },
    dicReparData () {
      return this.$store.state.dicReparData
    },
    reparTipoDes () {
      return this.$store.getters.reparTipoDes
    },
    reparDircDes () {
      return this.$store.getters.reparDircDes
    },
    dicTot () {
      return this.$store.state.dicTot
    },
    renglones () {
      return this.$store.state.dicPedido.renglones
    }
  },
  methods: {
    numberFormat (n) {
      return evNumberFormat[2].format(n)
    }
  },
};

</script>
