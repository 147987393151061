<template>
  <v-autocomplete
    ref="field"
    v-bind="localAttrs"
    :value="data"
    :errorMessages="error"
    :loading="okLoading"
    :search-input.sync="localSearch"
    @click:append="() => $emit('click:append')"
    @click:append-outer="() => $emit('click:append-outer')"
    @input="onInput"
    @change="onChange"
  >
    <template
      v-slot:selection="data"
      v-if="showChip"
    >
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        outlined
      >
        <v-avatar
          left
          v-if="showFileNi"
        >
          <v-img :src="`/drive/file/thumbnail/0/${data.item.file_ni}`">
          </v-img>
        </v-avatar>
        {{ data.item.value }}
        -
        {{ data.item.text }}
      </v-chip>

    </template>

    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar v-if="showFileNi">
          <img
            :src="`/drive/file/thumbnail/0/${data.item.file_ni}`"
            v-show="data.item.file_ni"
          >
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.text"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="showValue">
          <v-list-item-action-text v-html="data.item.value">
          </v-list-item-action-text>
        </v-list-item-action>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
/**
 * evu-field-autocomplete.vue
 * Ruben Valle
 * 17-08-2019
 * Wraper que da soporte de "modelo" al componente <v-autocomplete>.
 *
 * Ejemplo
 * --------------------------------------------------
 *  <evu-field-autocomplete
 *     field="loc"
 *     :form="form"
 *     lovQueryType="sync"
 *    }"
 *  ></evu-field-autocomplete>
 * 
 * En "modelo.options" residen los "items" a seleccionar. 
 * "modelo.options": puede actualizarse segun el parametro "lovQueryType"
 * lovQueryType:
 * null => "modelo.opcions" permanece inalterable.
 * mounted => "modelo.opcions" se carga desde el server en mounted(). 
 * sync => Carga desde el server a medida que se tipea. 
 * 
 * 05-11-2020
 * Se agrego soporte p/ showChip, showValue, showFileNi
 */

import axios from 'axios'
export default {
  name: 'evu-field-autocomplete',
  inheritAttrs: false,
  props: {
    form: { type: Object, required: true }, //  form = evForm(modelos)
    field: { type: String, required: true }, // Ej: 'cod', 'des', etc.
    lovUrl: { type: String }, // Ej: '/gra/public/api/lovTable/gra_loc/loc'

    // Ej: 
    // { _flt: "gra_loc.est='A' and gra_loc.prov=2" }
    // {_flt: "ccd_cta.dom = 0" }
    lovParams: { type: Object },

    // P/ cuando lovQueryType='sync' y queremos se autorefrenquen las opciones c/ vez que camdia el data
    // Ej:  {_flt: "ccd_cta.dom = 0 and ccd_cta.cta = 3445" }
    // Ver ejemplo en: ccd/faci: cmp-header-form.vue
    lovParamsSync: { type: Object },

    lovQueryType: { type: String },  // null, 'mounted', 'sync'

    loading: { type: Boolean },

    showFileNi: { type: Boolean }, // P/ cuando deseamos un avatar del file_ni
    showValue: { type: Boolean },
    showChip: { type: Boolean }
  },
  data () {
    return {
      localSearch: null,
      localLoading: false
    }
  },
  watch: {
    nextFocus () {
      if (this.nextFocus === this.field) {
        this.form.fieldFocus(null)
        this.focus()
      }
    },

    lovParamsSync () {
      // eslint-disable-next-line
      // console.log([this.modelo.label, 'watch: localLovParamsSync() v=', v])
      if (this.localLovQueryType === 'sync') {
        this.queryLov('', this.lovParamsSync)
      }
    },

    localLovParams () {
      // eslint-disable-next-line
      // console.log([this.modelo.label, 'watch: localLovParams() v=', v])
      if (!this.localLoading && this.localLovQueryType === 'mounted') {
        this.queryLov('')
      }
    },


    localSearch (v) {
      // eslint-disable-next-line
      // console.log([this.modelo.label, 'watch: localSearch() v=', v])

      if (!this.okLoading) {
        // Ver: ejemplo en stk/invi/ing.vue
        this.$emit('query-lov', v)

        if (this.localLovQueryType === 'sync') {
          this.queryLov(v)
        }
      }
    },
  },
  computed: {
    nextFocus () {
      return this.form.nextFocus
    },

    okLoading () {
      return this.loading || this.localLoading
    },
    modelo () {
      return this.form.modelos[this.field]
    },
    data () {
      return this.form.data[this.field]
    },
    error () {
      return this.form.errors[this.field] || this.form.vldsError[this.field]
    },
    localAttrs () {
      return {
        tabindex: 1,  // P/ que navegue entre fields
        clearable: true,
        label: this.modelo.label,
        required: !this.modelo.can_null,
        hint: this.modelo.hint,
        placeholder: 'Comience a escribir para buscar',
        'prepend-icon': this.localLovQueryType === 'sync' ? "mdi-database-search" : '',
        items: this.modelo.options,
        ...this.$attrs
      }
    },
    localLov () {
      return this.modelo.lov || {}
    },
    localLovUrl () {
      return this.lovUrl || this.localLov.url
    },
    localLovParams () {
      return this.lovParams || this.localLov.params || {}
    },
    localLovQueryType () {
      return this.lovQueryType || this.localLov.queryType
    }
  },
  methods: {
    /* eslint-disable */
    onInput (v) {
      // console.log(['YYYYYYYYYYYYYYYYYYYY  - evu-field-autocomplete.onImput(), v=', v])
      this.form.errors[this.field] = null
      this.form.vldsError[this.field] = null
      this.$emit('input', v)
    },
    onChange (v) {
      // console.log(['ZZZZZZZZZZZZZZZZZZZZZ - evu-field-autocomplete.onChange(), v=', v])
      this.form.fieldSetData(this.field, v)
      this.form.fieldVldModelo(this.field)
      this.$emit('change', this.form.data[this.field])
    },

    focus () {
      this.$refs.field.focus()
    },
    queryLov (v, params) {
      // eslint-disable-next-line
      // console.log([this.modelo.label, 'queryLov() v=', v, 'params=', params, 'localLovParams=', this.localLovParams])
      this.localLoading = true
      axios.post(
        this.localLovUrl,
        {
          _token: this.$root.evToken,
          _search: `%${v || ''}`,
          _slc_type: 'slc_min',
          ...(params || this.localLovParams)
        }
      )
        .then((response) => {
          if (response.data.error_nro === 0) {
            this.modelo.options = response.data.rows || response.data.options

            if (this.modelo.options.length === 1 && this.localLovQueryType === 'sync' && this.lovParamsSync && params === this.lovParamsSync) {
              this.localSearch = this.modelo.options[0].text
            }

          } else {
            this.$root.$evModal.showError(response.data.error_txt)
          }
        })
        .catch((error) => { this.$root.$evModal.showAxioError(error) })
        .finally(() => { this.localLoading = false })
    }
  },
  mounted () {
    // 12-04-2020: Ya no lo usamos p/ evitar "Error: Converting circular structure to JSON"
    // this.form.refs[this.field] = this
    // console.log(['evu-field-autocomlete.mounted() this.localLovParams=', this.localLovParams, 'this.data=', this.data])

    if (this.localLovQueryType === 'mounted') {
      // Cargamos todas las options a inicio una uni ves
      this.queryLov('')

    } else if (this.localLovQueryType === 'sync' && this.lovParamsSync) {
      // Cargamos las opciones al inicio solo si recibimos lovParamsSync
      this.queryLov('', this.lovParamsSync)
    }
  }
}
</script>
