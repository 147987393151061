const mutations = {
  // ======================================
  SET_CMP_ESTADIO: (state, cmpEstadio) => {
    state.cmpEstadio = cmpEstadio;
  },

  // ======================================
  SET_TIENDA_CONF: (state, tiendaConf) => {
    state.tiendaConf = tiendaConf;
  },

  // ======================================
  SET_OPTIONS_PRS: (state, options) => {
    state.optionsPrs = options;
  },

  // ======================================
  SET_OPTIONS_GRPGRPDEP: (state, options) => {
    state.optionsGrpgrpdep = options;
  },

  // ======================================
  SET_OPTIONS_GRPDEP: (state, options) => {
    state.optionsGrpdep = options;
  },

  // ======================================
  SET_OPTIONS_DEP: (state, options) => {
    state.optionsDep = options;
  },

  // ======================================
  SET_OPTIONS_PLU: (state, data) => {
    state.optionsPlu = data.options;
    state.optionsPluMar = data.optionsMar;
    state.optionsPluGrpgrpdep = data.optionsGrpgrpdep;
    state.optionsPluGrpdep = data.optionsGrpdep;
    state.optionsPluDep = data.optionsDep;
    state.pluSearch = data.search;
    state.dicGrpgrpdep = data.dicGrpgrpdep;
    state.dicGrpdep = data.dicGrpdep;
    state.dicDep = data.dicDep;
    state.dicMar = data.dicMar;
  },

  // ======================================
  SET_REPAR_DEPO_CANT: (state, reparDepoCant) => {
    state.reparDepoCant = reparDepoCant;
  },

  // ======================================
  SET_OPTIONS_REPAR_COD: (state, options) => {
    state.optionsReparCod = options;
  },

  // ======================================
  SET_DIC_PLU: (state, dicPlu) => {
    state.dicPlu = dicPlu;
  },

  SET_NRO_RENGLON_EDITING: (state, nroRenglonEditing) => {
    state.nroRenglonEditing = nroRenglonEditing;
  },

  SET_DIC_RENGLON_EDITING: (state, dicRenglonEditing) => {
    state.dicRenglonEditing = dicRenglonEditing;
  },
  // ======================================

  // ======================================
  SET_DIC_CTA: (state, dicCta) => {
    state.dicCta = dicCta;
  },

  // ======================================
  SET_DIC_TOT: (state, dicTot) => {
    state.dicTot = dicTot;
  },

  // ======================================
  SET_DIC_WEB_FCB: (state, dicWebFcb) => {
    state.dicWebFcb = dicWebFcb;
  },

  // ======================================
  SET_DIC_REPAR_COD: (state, dicReparCod) => {
    state.dicReparCod = dicReparCod;
  },

  // ======================================
  SET_DIC_REPAR_DATA: (state, dicReparData) => {
    state.dicReparData = dicReparData;
  },

  // renglones del pedido
  // ======================================================
  /*
    SET_RENGLONES: (state, renglones) => {
        state.dicPedido.renglones = renglones
        mutations.CAL_TOT(state)
    },
    */

  // Info del pedido
  // ======================================================
  SET_DIC_PEDIDO: (
    state,
    { ped_ni, ped_next_func, ped_next_func_msg, renglones }
  ) => {
    // eslint-disable-next-line no-console
    // console.log(['SET_DIC_PEDIDO'])
    state.dicPedido = {
      ped_ni,
      ped_next_func,
      ped_next_func_msg,
      renglones: renglones || [],
    };
    mutations.CAL_TOT(state);
  },

  // Calcula los totales
  // ======================================================
  CAL_TOT: (state) => {
    state.totImpM = 0;
    state.totCant = 0;
    state.dicPedido.renglones.forEach((dicRenglon) => {
      state.totCant += dicRenglon.cant;
      state.totImpM += dicRenglon.imp_m;
    });

    // eslint-disable-next-line no-console
    // console.log(['CAL_TOT state.dicPedido=', state.dicPedido])
    localStorage.setItem(
      "ccd_cliente-tienda-pedido",
      JSON.stringify(state.dicPedido)
    );
  },

  // Actuliza los renglones con problemas de stock
  // Lista de cod. sin stock:
  // lstCodSinStk = [{cod: 123, stk: -34}, ....]
  SET_LST_COD_SIN_STK: (state, lstCodSinStk) => {
    // console.log(['lstCodSinStk=', lstCodSinStk])
    lstCodSinStk.forEach((s) => {
      // console.log(['s=', s])
      let dic_renglon = state.dicPedido.renglones.find((r) => r.cod == s.cod);
      if (dic_renglon) {
        dic_renglon.stkdispo = s.stkdispo;
      }
    });
    // Regeneramos array p/ que sea reactivo
    state.dicPedido = { ...state.dicPedido };
  },
};

export default mutations;
