/* ev-axios.js
   Ruben Valle
   Ver. 1.0: 29-08-2019
   -------------------------
   Conjunto de funciones utiles para llamar a APIs de uso fercuente.
*/
import axios from 'axios'



// ==================================================================
export const evAxiosGet = async (url, nvlError) => {
    const res = await axios.get(url)
    const data = res.data
    if (data.error_nro <= (nvlError || 0)) {
        return data
    }
    if (data.error_nro === 1) {
        throw (`${data.error_txt}: ${JSON.stringify(data.errors, 0, ' ')}`)
    } else {
        throw (data.error_txt)
    }
}


// ==================================================================
export const evAxiosPost = async (url, params, nvlError) => {
    const res = await axios.post(url, params)
    const data = res.data
    if (data.error_nro <= (nvlError || 0)) {
        return data
    }
    if (data.error_nro === 1) {
        throw (`${data.error_txt}: ${JSON.stringify(data.errors)}`)
    } else {
        throw (data.error_txt)
    }
}


/**
* Ejempos: 
* evAxio({vm: this, url: '/gra/login/api/getAppConf'})
*   .then((data) => { this.appTitle = data.appConf.appTitle })
*
* evAxio({ vm: this, url: 'gra/login/api/getAppUser', key: 'appUser' })
*   .then((data) => { this.appUser = data.appUser })
*
* nvlError: Indica hasta que nivel de error debe ser manejado por evAxio()
*   >=9 => que no deseo que catch() muestre el mensaje de error
*/
// ==================================================================
export const evAxio = ({ vm, url, params, nvlError }) => {

    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then((response) => {
                // eslint-disable-next-line
                //console.log('evAxio.then')
                if (response.data.error_nro <= (nvlError || 0)) {
                    resolve(response.data)
                } else if (response.data.error_nro === 1) {
                    const error = `${response.data.error_txt}: ${JSON.stringify(response.data.errors)}`
                    vm.$root.$evModal.showError(error)
                    reject(error)
                } else {
                    vm.$root.$evModal.showError(response.data.error_txt)
                    reject(response.data.error_txt)
                }
            })
            .catch((error) => {
                // eslint-disable-next-line
                // console.log('evAxio.catch')
                if ((nvlError || 0) < 9) {
                    vm.$root.$evModal.showAxioError(error)
                }
                reject(error)
            })
    })
}

/**
* Ejempos:
*    evAxioFieldVldDes({
*        form: this.form,
*        field: 'pers1',
*        url: '/gra/public/api/vldDes/gra_pers/pers',
*        params: { pers: this.form.data.pers1 }
*    })
* 
*
*    loc4LovParams () {
*      return { _flt: `gra_loc.est = 'A' and gra_loc.prov = ${evDbS(this.form.data.prov, this.form.modelos.prov)}` }
*    }
*
*    evAxioFieldVldDes({
*        form: this.form,
*        field: 'loc4',
*        url: '/gra/public/api/vldDes/gra_loc/loc',
*        params: { loc: this.form.data.loc4, ...this.loc4LovParams }
*    })
*/
// ==================================================================
export const evAxioFieldVldDes = ({ form, field, url, params, fieldDes }) => {
    let vldData, vldError

    if (fieldDes) {
        // Reseteo campo vinculado
        form.fieldSetData(fieldDes, null)
    }

    form.vldBegin(field)
    axios.post(url, params)
        .then((response) => {
            if (response.data.error_nro === 0) {
                // Guardo resultado de la validacion
                vldData = response.data.dicData
                // console.log(['vldData=', vldData, 'fieldDes=', fieldDes])
                if (fieldDes) {
                    // Seteo campo vinculado
                    // console.log(['vldData.text=', vldData.text, 'fieldDes=', fieldDes, 'form=', form])
                    form.fieldSetData(fieldDes, vldData.text)
                    // console.log(['finv orm.fieldSetData(()'])

                }
            } else {
                vldError = response.data.error_txt
            }
        })
        .catch(() => { vldError = 'Error de comunicacion' })
        .finally(() => { form.vldEnd(field, vldData, vldError) })
}
