<!--
  plu-card
  13-08-2020
  Ruben Valle

  plu-card son las tarjetas VERTICALES
-->

<template>
  <div>
    <v-divider></v-divider>

    <v-card
      flat
      :loading="submiting"
    >
      <v-list-item @click="onSelectPlu">

        <v-list-item-avatar
          tile
          size="80"
        >
          <v-img
            :src="dicPlu.file_ni ? `/drive/file/thumbnail/0/${dicPlu.file_ni}` : ''"
            contain
          >
          </v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-subtitle>{{dicPlu.web_des}}</v-list-item-subtitle>
          <v-list-item-subtitle class="d-flex">
            <span class="primary--text text-caption font-weight-light">
              Cod: {{dicPlu.cod}}
            </span>

            <span
              class="ml-auto text-caption font-weight-light red--text"
              v-show="dicPlu.stkdispo < 1"
            >
              Sin stock!
            </span>

            <!--
            <span
              class="ml-auto text-caption font-weight-light"
              :class="stkColor"
            >
              Stock: {{dicPlu.stkdispo}} {{dicPlu.stat_z ? 'Kg' : ''}}
            </span>
            -->

          </v-list-item-subtitle>

          <v-list-item-title class="d-flex">
            <span class="accent--text">
              {{dicPlu.mone_simbolo}} {{numberFormat(dicPlu.prec_m)}}
            </span>
            <span class="ml-auto text-caption font-weight-light">
              {{dicPlu.stat_z ? 'Precio x Kg' : ''}}
            </span>

          </v-list-item-title>

          <v-list-item-subtitle>
            <v-chip
              dark
              secondary
              v-show="showPrecBulM"
            >
              {{dicPlu.stat_m}} x {{dicPlu.mone_simbolo}} {{ numberFormat(dicPlu.precbul_m)}} {{dicPlu.stat_z ? 'c/ Kg' : 'c/u'}}

            </v-chip>
          </v-list-item-subtitle>

        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>


<script>
import { evParser, evNumberFormat } from "@/evlib/utils/ev-modelo"
export default {
  name: "plu-list-item",
  props: {
    dicPlu: { type: Object },
  },
  data: () => ({
    submiting: false,
  }),

  computed: {
    showPrecBulM () {
      return this.dicPlu.stat_m && evParser(this.dicPlu.precbul_m, { type: 'evF' }) < evParser(this.dicPlu.prec_m, { type: 'evF' })
    },

    /*
    stkColor () {
      return this.dicPlu.stkdispo < 1 ? 'red--text' : ''
    }
    */
  },
  methods: {
    onSelectPlu () {
      this.submiting = true

      this.$store.dispatch(
        'cmdSelectPlu',
        {
          vm: this,
          cod: this.dicPlu.cod
        }
      )
        .finally(() => {
          this.submiting = false
        })
    },

    numberFormat (n) {
      return evNumberFormat[2].format(n)
    }
  }
}
</script>

