<template>

  <v-badge
    :content="cantProductos"
    color="warning"
    overlap
    left
    v-show="cantProductos"
  >
    <v-btn
      fab
      color="warning darken-1"
      small
      @click="onClick"
    >
      <v-icon>mdi-cart</v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
export default {
  name: "appbar-cart",
  computed: {
    cantProductos () {
      return this.$store.state.dicPedido.renglones.length
    },
  },
  methods: {
    onClick () {
      this.$emit('click')
    }

  }
}
</script>
