<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-information-outline </v-icon>
      </v-btn>
    </template>

    <v-card
      tile
      flat
    >

      <v-toolbar tile>
        <v-list-item-avatar tile>
          <v-img :src="dicPlu.file_ni ? `/drive/file/thumbnail/0/${dicPlu.file_ni}` : ''"></v-img>
        </v-list-item-avatar>

        <v-spacer></v-spacer>

        <v-toolbar-title class="primary--text">
          {{ dicPlu.cod }} - {{dicPlu.web_des}}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="onClick"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="height: 100%">
        <evu-md-html :cont="dicPlu.html_det"></evu-md-html>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>


<script>
import evuMdHtml from '@/evlib/evu-components/markdown/evu-md-html'
export default {
  name: "plu-html-det",
  components: {
    evuMdHtml
  },
  props: {
    dicPlu: { type: Object, required: true },
  },
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    onClick () {
      this.dialog = false

    }
  }
}
</script>

