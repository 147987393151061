<template>
  <v-list dense>

    <v-list-item :to="{name: 'plu-main', query: {'grpdep': grpdep} }">
      <v-list-item-title class="info--text wrap-text">
        {{ des }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item
      v-for="dep in deps"
      :key="dep.dep"
      :to="{name: 'plu-main', query: {'grpdep': grpdep, 'dep': dep.dep, search: search} }"
    >
      <v-list-item-subtitle class="wrap-text">
        {{ dep.des }} ({{dep.cant}})
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>

</template>

<script>
export default {
  name: 'plu-menu-grpdep',
  props: [
    'grpdep',
    'des'
  ],
  computed: {
    deps () {
      const self = this
      return this.$store.state.optionsPluDep.filter((dicDep) => {
        return dicDep.grpdep === self.grpdep
      })
    },
    search () {
      return this.$store.state.pluSearch || undefined
    }
  }
}
</script>


<style lang="scss" scoped>
.wrap-text {
  white-space: normal;
}
</style>
