<template>
  <div>
    <v-system-bar
      app
      :color="vmApp.appBarConf.color"
      :dark="vmApp.appBarConf.dark"
      :light="!vmApp.appBarConf.dark"
    >
      {{ appTitle }}
      <v-spacer></v-spacer>
      <evu-app-user-menu v-bind="appUser"></evu-app-user-menu>
    </v-system-bar>
    <v-app-bar
      app
      clipped-left
      :color="vmApp.appBarConf.extensionColor"
      :dark="vmApp.appBarConf.extensionDark"
      :light="!vmApp.appBarConf.extensionDark"
      dense
    >

      <v-app-bar-nav-icon @click="onClickDrawerLeft"></v-app-bar-nav-icon>

      <v-toolbar-items v-show="okSearch">
        <plu-search></plu-search>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <appbar-cart @click="onClickDrawerRight"></appbar-cart>

    </v-app-bar>
  </div>
</template>

<script>
import pluSearch from "@/components/plu-search"
import appbarCart from "@/components/appbar-cart"
import evuAppUserMenu from '@/evlib/evu-components/app/evu-app-user-menu'

/* eslint-disable */

export default {
  name: "appbar",
  props: {
    appTitle: { type: String },
    appUser: { type: Object },
  },
  data () {
    return {
      okSearch: true,
      okIconDrawer: true,
    };
  },
  inject: ["vmApp"],
  components: {
    pluSearch,
    appbarCart,
    evuAppUserMenu,
  },
  computed: {
    cantProductos () {
      return this.$store.state.dicPedido.renglones.length
    },
  },
  methods: {
    onClickDrawerLeft () {
      this.$emit('clickDrawerLeft')
    },
    onClickDrawerRight () {
      this.$emit('clickDrawerRight')
    }

  }
};
</script>


<style lang="scss" scoped>
.extensionToolBar {
  position: absolute;
  left: 0px;
  right: 0px;
}
</style>
