<!--
  plu-card
  13-08-2020
  Ruben Valle

  plu-card son las tarjetas VERTICALES
-->

<template>
  <div>
    <v-card
      :loading="submiting"
      @click="onSelectPlu"
    >
      <div>
        <div
          class="pa-2"
          style="height: 200px;"
        >
          <v-img
            :src="dicPlu.file_ni ? `/drive/file/index/0/${dicPlu.file_ni}` : ''"
            contain
            max-height="190px"
          />
        </div>

        <div
          class="px-2 pt-2"
          style="height: 80px;"
        >
          <div class="d-flex">
            <span class="text-caption font-weight-light primary--text">
              Cod: {{dicPlu.cod}}
            </span>

            <span
              class="ml-auto text-caption font-weight-light red--text"
              v-show="dicPlu.stkdispo < 1"
            >
              Sin stock!
            </span>

            <!--
            <span
              class="ml-auto text-caption font-weight-light"
              :class="stkColor"
            > Stock: {{dicPlu.stkdispo}} {{dicPlu.stat_z ? 'Kg' : ''}}
            </span>
            -->
            <br />
          </div>
          <span> {{ dicPlu.web_des }}</span><br />
        </div>

        <div
          class="px-2 pb-2 text-center"
          style="height: 95px;"
        >

          <span class="text-caption font-weight-light">{{dicPlu.stat_z ? 'Precio x Kg' : ''}}</span>
          <br />

          <span class="accent--text text-h5">
            {{dicPlu.mone_simbolo}}
            {{ numberFormat(dicPlu.prec_m) }}
          </span>
          <br />

          <v-chip
            dark
            secondary
            v-show="showPrecBulM"
          >
            {{dicPlu.stat_m}} x {{dicPlu.mone_simbolo}} {{ numberFormat(dicPlu.precbul_m)}} {{dicPlu.stat_z ? 'c/ Kg' : 'c/u'}}

          </v-chip>
          <br />

          <br />
        </div>
      </div>

      <div
        class="text-center"
        style="height: 50px;"
      >
        <plu-card-btn
          :dicPlu="dicPlu"
          small
          @click="onSelectPlu"
        ></plu-card-btn>
      </div>

    </v-card>

  </div>
</template>


<script>
import pluCardBtn from "@/components/plu-card-btn";
import { evNumberFormat, evParser } from "@/evlib/utils/ev-modelo"
export default {
  name: "plu-card",
  components: {
    pluCardBtn
  },
  props: {
    dicPlu: { type: Object },
  },
  data: () => ({
    submiting: false,
  }),

  computed: {
    showPrecBulM () {
      return this.dicPlu.stat_m && evParser(this.dicPlu.precbul_m, { type: 'evF' }) < evParser(this.dicPlu.prec_m, { type: 'evF' })
    }
    /*
    stkColor () {
      return this.dicPlu.stkdispo < 1 ? 'red--text' : ''
    }
    */
  },
  methods: {
    onSelectPlu () {
      this.$emit('click')
      this.submiting = true

      this.$store.dispatch(
        'cmdSelectPlu',
        {
          vm: this,
          cod: this.dicPlu.cod
        }
      )
        .finally(() => {
          this.submiting = false
        })
    },

    numberFormat (n) {
      return evNumberFormat[2].format(n)
    }
  }

}
</script>

