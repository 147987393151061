<template>
  <v-app-bar
    app
    clipped-left
    :color="vmApp.appBarConf.color"
    :dark="vmApp.appBarConf.dark"
    :light="!vmApp.appBarConf.dark"
    :height="vmApp.appBarConf.height"
    :extension-height="vmApp.appBarConf.extensionHeight"
  >

    <!-- Linea-1.Begin -->
    <v-app-bar-nav-icon @click="onClickDrawerLeft"></v-app-bar-nav-icon>

    <v-toolbar-title>
      <router-link to="/">
        <v-img
          :src="logoSrc"
          height="50"
          max-width="350"
          contain
          position="left"
        />
      </router-link>
    </v-toolbar-title>

    <v-toolbar-items v-show="okSearch">
      <plu-search></plu-search>
    </v-toolbar-items>

    <v-spacer></v-spacer>

    <appbar-cart @click="onClickDrawerRight"></appbar-cart>

    <!-- Linea-1.End -->

    <!-- Linea-2.Begin -->
    <template slot="extension">
      <v-toolbar
        flat
        tile
        :color="vmApp.appBarConf.extensionColor"
        :dark="vmApp.appBarConf.extensionDark"
        :light="!vmApp.appBarConf.extensionDark"
        :height="vmApp.appBarConf.extensionHeight"
        class="extensionToolBar"
      >
        <v-spacer></v-spacer>

        <v-toolbar-items v-show="okSearch">
          <appbar-categoria-menu></appbar-categoria-menu>
        </v-toolbar-items>

        <v-toolbar-items v-show="okSearch">
          <v-btn
            text
            to="/grpdep-menu"
          >
            subcategorías
          </v-btn>
        </v-toolbar-items>

        <v-spacer></v-spacer>

        <evu-app-user-menu v-bind="appUser"></evu-app-user-menu>
      </v-toolbar>

    </template>
    <!-- Linea-2.End -->

  </v-app-bar>

</template>

<script>
import pluSearch from "@/components/plu-search"
import appbarCart from "@/components/appbar-cart"
import appbarCategoriaMenu from "@/components/appbar-categoria-menu"
import evuAppUserMenu from '@/evlib/evu-components/app/evu-app-user-menu'

/* eslint-disable */

export default {
  name: "appbar",
  props: {
    appTitle: { type: String },
    appUser: { type: Object },
    loading: { type: Boolean }
  },
  data () {
    return {
      okSearch: true,
      okIconDrawer: true,
    };
  },
  inject: ["vmApp"],
  components: {
    pluSearch,
    appbarCart,
    appbarCategoriaMenu,
    evuAppUserMenu,
  },
  computed: {
    cantProductos () {
      return this.$store.state.dicPedido.renglones.length
    },
    logoSrc () {
      return this.vmApp.appBarConf.dark ? '/static/local/ccd_cliente/assets/logonavbar-dark.svg' : '/static//local/ccd_cliente/assets/logonavbar.svg'
    }
  },
  methods: {
    onClickDrawerLeft () {
      this.$emit('clickDrawerLeft')
    },
    onClickDrawerRight () {
      this.$emit('clickDrawerRight')
    }

  }
};
</script>


<style lang="scss" scoped>
.extensionToolBar {
  position: absolute;
  left: 0px;
  right: 0px;
}
</style>
