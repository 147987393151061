/* ev-axios3.js
   Ruben Valle
   17-12-2021
   -------------------------
   Conjunto de funciones utiles para llamar a APIs de uso fercuente.
   Cambiamos las funciones para trabajar con vue3    
   ----
*/
import axios from "axios";

const formatNetworkError = (error, url) => {
  // console.log(["error=", error, "url=", url]);
  let error_txt = String(error) + " *** url: " + url;
  /*
  let error_txt = String(error);
  if (error_txt.toLowerCase().includes("network error")) {
    error_txt = `Error: No se pudo comunicar con "${url}".`;
  }
  */
  return error_txt;
};

export default {
  wsHost: () => {
    // P/ cuando trabajamos con websockets
    const loc = window.location;
    // console.log(["wsHost() loc.host=", loc.host]);
    return (loc.protocol === "https:" ? "wss:" : "ws:") + "//" + loc.host;
  },

  // ==================================================================
  get: async (url) => {
    try {
      const res = await axios.get(url);
      const data = res.data;

      if (data.error_nro === 1) {
        data.error_txt = `${data.error_txt}: ${JSON.stringify(data.errors)}`;
      }
      return data;
    } catch (error) {
      console.log(["1) evAxios.get() error=", error]);
      // console.log(['2) evAxios.get() error=', String(error)])
      // console.log(['3) evAxios.get() error=', typeof error])

      return {
        error_nro: 9,
        error_txt: formatNetworkError(error, url),
        errors: {},
        data: {},
      };
    }
  },

  // ==================================================================
  post: async (url, params) => {
    try {
      const res = await axios.post(url, params);
      const data = res.data;

      if (data.error_nro === 1) {
        data.error_txt = `${data.error_txt}: ${JSON.stringify(data.errors)}`;
      }
      return data;
    } catch (error) {
      // console.log(['1) evAxios.post() error=', error])

      return {
        error_nro: 9,
        error_txt: formatNetworkError(error, url),
        errors: {},
        data: {},
      };
    }
  },
};
