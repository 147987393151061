<template>
  <div>
    <v-list subheader>
      <v-list-item-group
        v-model="localReparCod"
        color="primary"
      >

        <v-subheader
          class="primary--text"
          v-show="optionsReparCodC.length"
        >
          Recoger en un punto de entrega:
        </v-subheader>

        <template v-for="option in optionsReparCodC">
          <v-list-item
            :key="option.repar_cod"
            :label="option.des"
            :value="option.repar_cod"
          >

            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="option.des"></v-list-item-title>
                <v-list-item-subtitle v-show="option.envio_prec">
                  Costo de envio: $ {{ option.envio_prec }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action-text>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action-text>
            </template>

          </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-subheader
          class="primary--text"
          v-show="optionsReparCodD.length"
        >
          Envio a domicilio:
        </v-subheader>

        <template v-for=" option in optionsReparCodD">
          <v-list-item
            :key="option.repar_cod"
            :label="option.des"
            :value="option.repar_cod"
          >

            <template v-slot:default="{ active }">

              <v-list-item-content>
                <v-list-item-title v-text="option.des"></v-list-item-title>
                <v-list-item-subtitle>
                  Costo de envio: {{ txtCostoEnvio(option) }}

                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
            </template>

          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>

    <v-container class="text-center">
      <v-btn
        @click="continuar"
        outlined
        rounded
        color="primary"
        :disabled="!localReparCod"
        :loading="loading"
      >Continuar</v-btn>
    </v-container>
  </div>

</template>

<script>

export default {
  name: "fin-slc-repar-cod",
  props: {
    loading: { type: Boolean }
  },
  data () {
    return {
      localReparCod: null
    }
  },
  computed: {
    optionsReparCodC () {
      return this.$store.state.optionsReparCod.filter(v => v.repar_tipo === 'C')
    },
    optionsReparCodD () {
      return this.$store.state.optionsReparCod.filter(v => v.repar_tipo === 'D')
    },
  },
  methods: {
    continuar () {
      const d = this.$store.state.optionsReparCod.find(v => v.repar_cod === this.localReparCod) || {}
      this.$store.commit('SET_DIC_REPAR_COD', d)
      this.$emit('input')
    },
    txtCostoEnvio (dicReparCod) {
      if (!dicReparCod.envio_prec1) {
        return 'Sin cargo'
      }
      if (!dicReparCod.fac_tot_envio_prec2) {
        return `$ ${dicReparCod.envio_prec}`
      }
      if (!dicReparCod.envio_prec2) {
        return `$ ${dicReparCod.envio_prec1} (O sin cargo por compra mayor a $ ${dicReparCod.fac_tot_envio_prec2})`
      }

      return `$ ${dicReparCod.envio_prec1} (o $ ${dicReparCod.envio_prec2} por compra mayor a $ ${dicReparCod.fac_tot_envio_prec2})`
    }
  },

  mounted () {
    // console.log(['fin-slc-repar-cod.mouted(): dicReparCod', this.dicReparCod, 'localReparCod=', this.localReparCod])
    this.localReparCod = this.$store.state.dicReparCod.repar_cod

  }
}
</script>
