import Vue from 'vue'
import Router from 'vue-router'


// Usar solo cuando %root.evSessionType >=1 (Ver ev-session.js)
// -----------------------------v
import login from '@/evlib/evu-components/session/ev-login'
import logout from '@/evlib/evu-components/session/ev-logout'
import change_password from '@/evlib/evu-components/session/ev-change-password'
import reset_password from '@/evlib/evu-components/session/ev-reset-password'
import create_user from '@/evlib/evu-components/session/ev-create-user'

// -----------------------------^

import inicio from '@/components/inicio'
import pluMain from '@/components/plu-main'
import grpdepMenu from '@/components/grpdep-menu'
import finCompra from '@/components/fin-compra'
import finConfirmacion from '@/components/fin-confirmacion'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: login
        },
        {
            path: '/logout',
            name: 'logout',
            component: logout
        },
        {
            path: '/change_password',
            name: 'change_password',
            component: change_password
        },

        {
            path: '/reset_password',
            name: 'reset_password',
            component: reset_password
        },

        {
            path: '/create_user',
            name: 'create_user',
            component: create_user
        },

        {
            path: '/plu-main',
            name: 'plu-main',
            component: pluMain
        },
        {
            path: '/grpdep-menu',
            name: 'grpdep-menu',
            component: grpdepMenu
        },
        {
            path: '/fin-compra',
            name: 'fin-compra',
            component: finCompra
        },
        {
            path: '/fin-confirmacion',
            name: 'fin-cofirmacion',
            component: finConfirmacion
        },

        {
            path: '/',
            name: 'inicio',
            component: inicio
        },
    ],
    // Se agrego p/ asegurarnos que comienza con l scroll en el TOP
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})
