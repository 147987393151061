<template>

  <div>
    <v-btn
      rounded
      color="primary"
      @click.stop="agregar"
      :disabled="disableBtnAgregar"
      v-show="!cant"
    >
      <v-icon>mdi-cart</v-icon>
      Agregar

    </v-btn>

    <div style="width: 10em; margin: 0 auto;">
      <v-text-field
        :label="dicPlu.stat_z ? 'Cantidad de Kg:' : 'Cantidad de Unidades:'"
        outlined
        dense
        type="number"
        ref="field"
        v-show="cant"
        :value="cant"
        @change="onChange"
        :readonly="!!this.dicPlu.web_lst_prs"
        @click.stop=""
      ></v-text-field>

    </div>

  </div>
</template>

<script>
import { evParser } from "@/evlib/utils/ev-modelo"

export default {
  name: "plu-card-btn",
  props: {
    dicPlu: { type: Object },
  },
  inject: ['vmApp'],
  computed: {
    dicRenglon () {
      return (
        this.$store.state.dicPedido.renglones.find(dicRenglon => {
          return dicRenglon.cod === this.dicPlu.cod;
        }) || {}
      );
    },
    cant () {
      // console.log([this.cod, 'this.dicRenglon.cant=', this.dicRenglon.cant])
      // console.log('VALOR TEXTO ' + this.dicRenglon.des);
      return this.dicRenglon.cant;
    },
    disableBtnAgregar () {
      return this.dicPlu.stkdispo < 1
        || !this.$store.state.dicReparCod.repar_cod
        || (this.$store.state.tiendaConf.fpg_cta === 'S' && !this.vmApp.appUser.user)
    }

  },
  methods: {
    agregar () {
      // eslint-disable-next-line no-console
      // console.log(["plu-card-btn.agregar()"])
      if (this.dicPlu.web_lst_prs) {
        this.$emit('click')
      } else {
        this.onChange(1)
        this.$nextTick(() => this.$refs.field.focus())
      }
    },

    onChange (value) {
      // eslint-disable-next-line no-console
      // console.log(['plu-card-btn.onChange'])
      value = evParser(value, { type: "evN" })

      if (value <= 0) {
        value = 0;
      }

      if (value && value > this.dicPlu.stkdispo) {
        this.$root.$evModal.showError(
          `Stock Disponible: ${this.dicPlu.stkdispo} ${this.dicPlu.stat_z ? 'Kg' : 'Unidades'}.`
        )
        value = 0
        this.dicRenglon.lstPrsCant = []
      }

      this.$store.dispatch(
        "cmdRenglonUpdate",
        {
          cod: this.dicPlu.cod,
          web_des: this.dicPlu.web_des,
          prec_m: this.dicPlu.prec_m,
          precbul_m: this.dicPlu.precbul_m,
          stat_m: this.dicPlu.stat_m,
          stat_z: this.dicPlu.stat_z,
          file_ni: this.dicPlu.file_ni,
          stkdispo: this.dicPlu.stkdispo,
          cant: value,
          lstPrsCant: this.dicRenglon.lstPrsCant
        }
      )
    },

  }
}
</script>
