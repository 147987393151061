/* ev-session.js
*  Ruben Valle
*  Ver. 1.0: 16-06-2020
*  -------------------------
*  Funciones para mantener una session (Json Web Token)
*
*  $root.evSessionType indica el tipo de session que estamos usando
*    0= El servidor es el encargado de llevar la session. 
*       Los datos den usuario se guardan em vmApp.appUser{}
*       No usamos token.
*    1= Usamos token.  
*       La session de guarda en $root.evToken + vmApp.appUser
*    2= Idem a 1 y ademas se guarda la session en localStorage.
*/


/* Si $root.evSessionType = 0 o undefined
*  =======================================
*  En vmApp.
*  ----------------------
*  mounted() {
*    evAxio({ vm: this, url: '/gra/login/api/getAppUser' })
*        .then((data) => {
*          this.appUser = data.appUser
*        }
*    )
*  }
*/


/* Si $root.evSessionType >=1 
*  =======================================
*  En vmApp
*  --------------------
*  created() {
*     evSessionInit(this, 2)
*
*     // Lee session desde localStorage
*     evSessionRead(this)
*  }
*
*  En router.index.js  
*  ---------------------
*  import login from '@/evlib/evu-components/session/ev-login'
*  import logout from '@/evlib/evu-components/session/ev-logout'
*  import change_password from '@/evlib/evu-components/session/ev-change-password'
*
*  routes: [
*    { 
*        path: '/login',
*        name: 'login',
*        component: login
*     },
*     {
*        path: '/logout',
*        name: 'logout',
*        component: logout
*     },
*     {
*        path: '/change_password',
*        name: 'change_password',
*        component: change_password
*      },
*
*
*  En los componentes.vue que necesiten autenticacion
* ---------------------------------------------------
* beforeRouteEnter (to, from, next) {
*    next(vm => {
*      if (!vm.$root.evUser) {
*        vm.$router.replace({ path: '/login', query: { nextUrl: to.fullPath } })
*      }
*    })
* }
*
*  En cada llamada a evAxio se debe pasar el _token
* ---------------------------------------------------
*      evAxio({
*        vm: this,
*        url: '/demovue/vudemo3/api/submitForm1',
*        params: { _token: this.$root.evToken, ...this.form.data },
*        nvlError: 1
*      })
*
* evu-app-user-menu.vue
* ----------------------------------------------------
* El componente evu-app-user-menu.vue se adapta automaticamente segun $root.evSessionTypebundleRenderer.renderToStream
*
*/
import moment from 'moment'

export const evSessionUpdate = (vmApp, { token = null, expiration = null, user = null, userDes = null }) => {
    vmApp.$root.evToken = token
    vmApp.$root.evExpiration = expiration
    vmApp.$root.evUser = user
    vmApp.$root.evUserDes = userDes

    // En la pantalla mostramos "appUser.user" en lugar de "$root.evUser" debido 
    // a que "$root.evUser" no siempre funciona el "reactive".

    vmApp.appUser = {
        ...vmApp.appUser,
        user: user,
        userDes: userDes
    }
}


// Inicializa Session
// sessionType:
//   0= No usa token. El servidor es el encargado de llevvar la session
//   1= Usa $root.evToken y vmApp.appUser, pero no guarda en localStorage.
//   2= Idem a 1, pero guarda session en localStorage.

// sessionId: 'gra', 'ccd_cliente', 'cca', 'trj_cns_soc', 'trj_cns_comer', 'cobcen_cns_emp'.
// 
export const evSessionInit = (
    {
        vmApp,
        sessionType,
        sessionId,
        userLabel,
        canResetPasswd,
        canCreateUser
    }
) => {
    vmApp.$root.evSessionType = sessionType || 0

    vmApp.$root.evSessionId = sessionId || 'gra'

    vmApp.$root.evSessionUserLabel = userLabel || 'Cod. de Operador'

    vmApp.$root.evSessionCanResetPasswd = canResetPasswd || false
    vmApp.$root.evSessionCanCreateUser = canCreateUser || false

    // Reset
    evSessionUpdate(vmApp, {})
}



// Remueve desde localStorage y vmApp.appUser
export const evSessionRemove = (vmApp) => {
    if (vmApp.$root.evSessionType >= 2) {
        localStorage.removeItem(`ev${vmApp.$root.evSessionId}Token`)
        localStorage.removeItem(`ev${vmApp.$root.evSessionId}TokenExpiration`)
        localStorage.removeItem(`ev${vmApp.$root.evSessionId}User`)
        localStorage.removeItem(`ev${vmApp.$root.evSessionId}UserDes`)
    }
    // Reset
    evSessionUpdate(vmApp, {})
}


// Escribe en localStorage
export const evSessionWrite = (vmApp, { token, expiration, user, userDes }) => {
    if (vmApp.$root.evSessionType >= 2) {
        localStorage.setItem(`ev${vmApp.$root.evSessionId}Token`, token || '')
        localStorage.setItem(`ev${vmApp.$root.evSessionId}TokenExpiration`, expiration || '')
        localStorage.setItem(`ev${vmApp.$root.evSessionId}User`, user || '')
        localStorage.setItem(`ev${vmApp.$root.evSessionId}UserDes`, userDes || '')
    }
    evSessionUpdate(vmApp, { token, expiration, user, userDes })
}

// Lee session desde localStorage 
export const evSessionRead = (vmApp) => {
    evSessionUpdate(vmApp, {
        token: localStorage.getItem(`ev${vmApp.$root.evSessionId}Token`),
        expiration: localStorage.getItem(`ev${vmApp.$root.evSessionId}TokenExpiration`),
        user: localStorage.getItem(`ev${vmApp.$root.evSessionId}User`),
        userDes: localStorage.getItem(`ev${vmApp.$root.evSessionId}UserDes`)
    })

    if (vmApp.$root.evToken) {
        // Me aseguro que el token no este a punto de vencerse.
        if (!vmApp.$root.evExpiration || moment().add(1, 'hours') > moment(vmApp.$root.evExpiration)) {
            evSessionRemove(vmApp)
        }
    }
}

