<template>
  <v-container fluid>
    <v-list dense>

      <v-list-item :to="{name: 'plu-main', query: {'grpdep': grpdep} }">
        <v-list-item-title class="info--text">
          {{ des }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-for="dep in deps"
        :key="dep.dep"
        class="list-min-height"
        dense
        :to="{name: 'plu-main', query: {'grpdep': grpdep, 'dep': dep.dep} }"
      >
        <v-list-item-subtitle>
          {{ dep.des }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-container>
</template>


<script>
export default {
  name: 'grpdep-card',
  props: [
    'grpdep',
    'des'
  ],
  computed: {
    deps () {
      const self = this
      return this.$store.state.optionsDep.filter(
        (dicDep) => { return dicDep.grpdep === self.grpdep }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.list-min-height {
  min-height: 30px;
}
</style>


