<template>
  <div>
    <v-dialog
      v-model="dlgOk"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ dlgOption.title }}
          <v-spacer />
          <v-icon @click="dlgOk = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text v-html="dlgOption.html">
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dlgOk = false"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogTiendas"
      width="500"
    >
      <v-card>
        <v-card-title>
          Tiendas
          <v-spacer />
          <v-icon @click="dialogTiendas = false">mdi-close</v-icon>

        </v-card-title>

        <v-card-text
          v-for="(option, n) in sucursales"
          :key="n"
          class="text-center"
        >
          <b>{{ option.title }} </b>
          <br />
          {{ option.dir }}
          <br />
          {{ option.loc }}
          <br />
          <span v-if="option.tel">
            <v-icon color=grey>mdi-phone-outline</v-icon>
            {{ option.tel }}
            <br />
          </span>
          <span v-if="option.ws">
            <v-icon color=grey>mdi-whatsapp</v-icon>
            {{ option.ws }}
            <br />
          </span>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogTiendas = false"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card
      flat
      tile
      dark
      class="grey darken-4 grey--text"
    >

      <v-row class="grey lighten-4 grey--text text--darken-4">

        <v-col
          v-for="(option, n) in menu"
          :key="n"
          cols="12"
          sm="3"
          class="text-center py-6"
        >
          <v-icon
            x-large
            light
            @click="onClick(option)"
          >
            {{ option.icon }}
          </v-icon>
          <br />
          {{ option.title}}
          <br />
          <small>{{ option.subtitle }}</small>
        </v-col>

      </v-row>

      <v-container>
        <v-container>
          <v-img
            :src="logoSrc"
            height="70"
            max-width="350"
            contain
            class="pm-6 mx-auto"
          />
        </v-container>

        <v-container
          class="text-center"
          v-if="tel"
        >
          <span>
            <v-icon color=grey>mdi-phone-outline</v-icon>
            {{ tel }}
          </span>
        </v-container>

        <v-container class="text-center">
          <div>
            <v-btn
              v-for="(social, i) in sociales"
              :key="i"
              icon
              color="grey"
              class="mx-4"
              :href="social.url"
            >
              <v-icon
                :key="-i"
                x-large
              >{{ social.icon }}</v-icon>
            </v-btn>

          </div>
        </v-container>

        <v-row justify="center">
          <v-col
            v-for="(option, n) in sucursales"
            :key="n"
            class="text-center"
            cols="12"
            sm="6"
            md="4"
          >
            <b>{{ option.title }} </b>
            <br />
            {{ option.dir }}
            <br />
            {{ option.loc }}
            <br />
            <span v-if="option.tel">
              <v-icon color=grey>mdi-phone-outline</v-icon>
              {{ option.tel }}
              <br />
            </span>
            <v-btn
              v-if="option.ws"
              :href="wsHref(option.ws)"
              text
              target="blank"
            >
              <v-icon color=grey>mdi-whatsapp</v-icon>
              {{ option.ws }}
              <br />
            </v-btn>
          </v-col>
        </v-row>

        <v-container v-if="afipSrc">
          <v-card
            flat
            tile
            :href="afipHref"
          >
            <v-img
              :src="afipSrc"
              height="70"
              max-width="400"
              contain
              class="pm-6 mx-auto"
            />
          </v-card>
        </v-container>

        <v-container class="text-center">
          <v-card
            flat
            tile
            href="http://www.electronicavalle.com.ar"
          >
            <span
              class="grey--text text-caption"
              v-html="vmApp.footer"
            ></span>
          </v-card>
        </v-container>
      </v-container>

    </v-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogTiendas: false,
      dlgOk: false,
      dlgOption: {}
    }
  },
  inject: ["vmApp"],
  computed: {
    logoSrc () {
      return this.vmApp.appBarConf.dark ? '/static/local/ccd_cliente/assets/logonavbar-dark.svg' : '/static/local/ccd_cliente/assets/logonavbar.svg'
    },

    tel () {
      return this.vmApp.appFooterConf.tel
    },
    sociales () {
      return this.vmApp.appFooterConf.sociales || []
    },
    sucursales () {
      return this.vmApp.appFooterConf.sucursales || []
    },
    menu () {
      return this.vmApp.appFooterConf.menu || []
    },
    afipHref () {
      return this.vmApp.appFooterConf.afipHref
    },
    afipSrc () {
      return this.vmApp.appFooterConf.afipSrc
    },

  },
  methods: {
    onClick (option) {
      // console.log(['onClick() option=', option])
      if (option.icon == 'mdi-storefront') {
        this.dialogTiendas = !this.dialogTiendas
      } else if (option.html) {
        this.dlgOk = true
        this.dlgOption = option

      }
    },
    wsHref (option) {
      return `https://api.whatsapp.com/send?phone=54${option}`
    }
  }
}
</script>