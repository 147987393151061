<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="info--text">
          Marcas
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-for="mar in mars"
      :key="mar.mar"
      :to="{name: 'plu-main', query: {'grpdep': grpdep, 'dep': dep, 'mar': mar.mar, 'search': search} }"
    >
      <v-list-item-subtitle class="wrap-text">
        {{ mar.des }} ({{mar.cant}})
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>

</template>

<script>
export default {
  name: 'plu-menu-mar',
  computed: {
    grpdep () {
      return this.$store.state.dicGrpdep.grpdep
    },
    dep () {
      return this.$store.state.dicDep.dep
    },
    mars () {
      return this.$store.state.optionsPluMar
    },
    search () {
      return this.$store.state.pluSearch || undefined
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap-text {
  white-space: normal;
}
</style>
