<template>
  <v-card :disabled="submiting">

    <v-card color="grey lighten-4">
      <v-container class="text-center">
        <v-row>
          <v-btn
            icon
            @click="closeDrawerRight"
            class="ml-auto"
          >
            <v-icon class="primary--text">mdi-close</v-icon>

          </v-btn>
        </v-row>

        <v-btn
          rounded
          @click="finalizar"
          color="success"
          :disabled="!cantRenglones || totImpM < facTotMin"
        >
          Finalizar Compra
        </v-btn>
      </v-container>
      <v-container class="text-center">
        <v-alert
          dense
          type="error"
          v-show="totImpM < facTotMin"
        >
          Compra Mínima: $ {{ facTotMin }}
        </v-alert>

        <v-alert
          dense
          type="error"
          v-show="showSinStock"
        >
          Corregir la cantidad en los renglones sin stock suficiente.
        </v-alert>

        <span class="text-h5 grey--text text--darken-1">Total: $ </span> <span class="text-h4 accent--text"> {{numberFormat(totImpM) }} </span>
      </v-container>
    </v-card>

    <v-divider></v-divider>

    <v-container class="text-center">
      <v-btn
        @click="vaciar"
        outlined
        rounded
        color="secondary"
        v-show="!showConfVaciar"
      >
        Vaciar <v-icon>mdi-cart</v-icon>
      </v-btn>
      <v-btn
        @click="confVaciar"
        outlined
        rounded
        color="info"
        v-show="showConfVaciar"
      >
        Confirmar Vaciado

      </v-btn>

    </v-container>
    <v-alert
      v-show="okPesables"
      v-model="alert"
      dismissible
      outlined
      type="warning"
      prominent
      border="left"
      class="mx-1"
    >
      {{ vmApp.appTextConf.advPrsCant}}
    </v-alert>

    <v-list>

      <v-list-item
        v-for="(item, i) in renglones"
        :key="i"
        three-line
      >
        <v-list-item-avatar>
          <v-img :src="item.file_ni ? '/drive/file/thumbnail/0/' + item.file_ni : ''"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-subtitle class="primary--text">
            <small>Cod: {{item.cod}}</small>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{item.web_des}}
          </v-list-item-subtitle>

          <v-list-item-title class="accent--text d-flex">
            $ {{numberFormat(item.imp_m)}}
            <v-spacer></v-spacer>
            <v-chip
              smallXXX
              dark
              color="error"
              v-show="item.cant > item.stkdispo"
            >
              {{`Stock: ${item.stkdispo} ${item.stat_z ? 'Kg' : 'Unidades'}.`}}

            </v-chip>

          </v-list-item-title>

          <v-list-item-title>
            <template v-for="({des, prs_cant}, n) in item.lstPrsCant.filter((d) => d.prs_cant)">
              <span
                class="ml-8 text-caption font-weight-light"
                :key="n"
              >
                {{des}}: {{prs_cant}}
                <br />
              </span>
            </template>
            <v-text-field
              :readonly="!!item.lstPrsCant.length"
              :prefix="item.stat_z ? 'Cant. de Kg:' : 'Unidades:'"
              outlined
              dense
              type="number"
              :value="item.cant"
              @change="(value) => change(value, item)"
            ></v-text-field>

          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn
            icon
            @click="deleteItem(item)"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>

          <v-btn
            icon
            @click="alert = !alert"
            color="warning"
            v-show="item.stat_z"
          >
            <v-icon>mdi-alert-circle-outline</v-icon>
          </v-btn>

        </v-list-item-action>

      </v-list-item>

    </v-list>
  </v-card>
</template>

<script>
import { evParser, evNumberFormat } from "@/evlib/utils/ev-modelo"

/* eslint-disable */

export default {
  name: "checkout",
  inject: ['vmApp'],
  data () {
    return {
      alert: true,
      showConfVaciar: false,
      submiting: false,
      lstMsg: ['1/2 Ppieza: 1', 'Pieza completa: 3'],
    }
  },
  computed: {
    renglones () {
      return this.$store.state.dicPedido.renglones
    },
    cantRenglones () {
      return this.renglones.length
    },
    showSinStock () {
      return -1 < this.renglones.findIndex((e) => e.cant > e.stkdispo)
    },
    totImpM () {
      return this.$store.state.totImpM
    },
    okPesables () {
      return this.renglones.findIndex(renglon => renglon.stat_z) >= 0
    },

    facTotMin () {
      return this.$store.state.dicReparCod.fac_tot_min || 0
    }

  },
  methods: {
    numberFormat (n) {
      return evNumberFormat[2].format(n)
    },
    deleteItem (item) {
      // console.log(["deleteItem() item=", item]);
      this.change(0, item)
    },

    change (value, item) {
      // console.log(["change()", "value=", value, "item.cant=", item.cant]);
      value = evParser(value, { type: "evN" });


      if (value <= 0) {
        value = 0;
        // this.$refs.cant.$el.value = value // Artilugio para que se refresque el <input>
      }


      // OjO:
      // Agregramo "item.cant = value" y "this.$nextTick(() => { ...",
      // para contemplar los casos como cuando tengo dos renglones cargados y los dos con cant=1.
      // Entonces editamos el 1er renglon y hacemos cant=0. En la caction cmdRenglonUpdate()
      // se eliminara el 1er elemento de dicPedido.renglones.
      // Referscarde la pantalla se vera un solo renglon con el cod y web_des bien, pero 
      // en "cant" seguira diciendo "0" a pesar que el correspondiente item.cant=1.
      // 
      // -----------------v
      item.cant = value

      this.$nextTick(() => {
        this.$store.dispatch(
          'cmdRenglonUpdate',
          {
            vm: this,
            cod: item.cod,
            web_des: item.web_des,
            prec_m: item.prec_m,
            precbul_m: item.precbul_m,
            stat_m: item.stat_m,
            stat_z: item.stat_z,
            file_ni: item.file_ni,
            stkdispo: item.stkdispo,
            cant: value,
            lstPrsCant: item.lstPrsCant
          }
        )
      })
      // -----------------^

    },

    vaciar () {
      this.showConfVaciar = true
      setTimeout(() => {
        this.showConfVaciar = false
      }, 5000)
    },
    confVaciar () {
      this.showConfVaciar = false
      this.$store.dispatch('cmdVaciarCarrito')
    },
    closeDrawerRight () {
      this.vmApp.drawerRight = false
    },
    finalizar () {
      this.closeDrawerRight()
      this.$router.replace({ path: '/fin-compra' })
    },

  }
}

</script>

