<template>
  <v-dialog
    :value="okDialog"
    max-width="1000"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    persistent
    scrollable
  >
    <v-card
      flat
      tile
      :loading="submiting"
      :disabled="submiting"
    >
      <v-toolbar dense>
        <v-btn
          icon
          @click="cmdClose"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>{{ title }}</v-toolbar-title>

        <!--
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="cmdAsentar"
        > {{ btnText }}</v-btn>
        -->

      </v-toolbar>

      <v-container fluid>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card>
              <v-list class="grey lighten-5">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle class="primary--text">
                      <div class="d-flex">
                        Código: {{dicRenglonEditing.cod}}
                        <v-spacer></v-spacer>
                      </div>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle>
                      {{ dicPlu.web_des }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle>
                      <span class="text-subtitle-2">Unidades x Bulto: </span> {{dicPlu.uni}}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-show="dicPlu.stat_z && dicPlu.peso">
                      <span class="text-subtitle-2">Peso de una Pieza: </span> {{ dicPlu.peso}} Kg
                    </v-list-item-subtitle>

                    <!--
                    <v-list-item-subtitle>
                      <span class="text-subtitle-2">Stock Disponible: </span> {{dicPlu.stkdispo}} {{dicPlu.stat_z ? 'Kg' : 'Unidades'}}
                    </v-list-item-subtitle>
                    -->

                    <v-list-item-subtitle>
                      <span class="text-subtitle-2">Precio x {{dicPlu.stat_z ? 'Kg' : 'Unidad'}}: </span> <span class="accent--text">{{dicPlu.mone_simbolo}} {{ numberFormat(dicPlu.prec_m) }}</span>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-show="showPrecBulM">
                      <v-chip
                        dark
                        secondary
                      >
                        {{dicPlu.stat_m}} x {{dicPlu.mone_simbolo}} {{ numberFormat(dicPlu.precbul_m)}} {{dicPlu.stat_z ? 'c/ Kg' : 'c/u'}}

                      </v-chip>
                    </v-list-item-subtitle>

                  </v-list-item-content>

                  <v-list-item-action>
                    <plu-html-det :dicPlu="dicPlu"></plu-html-det>
                    <plu-web-fcb :dicPlu="dicPlu"></plu-web-fcb>
                  </v-list-item-action>

                </v-list-item>

              </v-list>
            </v-card>

            <form @submit.prevent="cmdAsentar">
              <v-container>
                <v-text-field
                  v-show="!lstPrsCant.length"
                  :label="dicPlu.stat_z ? 'Cantidad de Kg:' : 'Cantidad de Unidades:'"
                  clearable
                  outlined
                  type="number"
                  v-model="cant"
                  @change="onChange"
                  :autofocus="!lstPrsCant.length"
                ></v-text-field>

                <plu-card-prs-cant-edit
                  v-show="lstPrsCant.length"
                  v-model="lstPrsCant"
                  @change="onChange"
                >
                </plu-card-prs-cant-edit>

              </v-container>
              <v-alert
                dense
                type="error"
                v-show="!!error"
              >
                {{error}}
              </v-alert>
              <div class="text-center">
                <v-btn
                  color="primary"
                  class="mx-2"
                  outlined
                  rounded
                  @click="cmdAsentar"
                >
                  {{ btnText }}
                </v-btn>
              </div>
            </form>
          </v-col>

          <v-col
            cols="12"
            md="6"
            v-show="dicPlu.lst_drive_file"
          >
            <v-card>
              <v-carousel
                height="320px"
                hide-delimiters
              >
                <template v-for="(item, i) in dicPlu.lst_drive_file">
                  <v-carousel-item
                    :key="i"
                    color="green"
                  >

                    <div
                      class="mx-auto pa-2"
                      width="300"
                    >
                      <v-img
                        :src="`/drive/file/index/0/${item.file_ni}`"
                        contain
                        max-height="250px"
                      />
                    </div>

                  </v-carousel-item>
                </template>
              </v-carousel>
            </v-card>
          </v-col>
        </v-row>

      </v-container>

    </v-card>
  </v-dialog>
</template>


<script>
import { evNumberFormat } from "@/evlib/utils/ev-modelo"

import pluCardPrsCantEdit from '@/components/plu-card-prs-cant-edit'
import pluHtmlDet from '@/components/plu-html-det'
import pluWebFcb from '@/components/plu-web-fcb'
import { evParser } from "@/evlib/utils/ev-modelo"
import { calcLstPrsCant, sumLstPrsCant } from '@/utils/prs-cant'

export default {
  name: 'plu-card-edit',
  components: {
    pluCardPrsCantEdit,
    pluHtmlDet,
    pluWebFcb,
  },
  data () {
    return {
      submiting: false,
      cant: 0,
      lstPrsCant: [],
      error: '',
    }
  },
  watch: {
    okDialog () {
      if (this.okDialog) {
        this.resetAll()
      }
    }
  },
  computed: {
    okDialog () {
      return this.$store.state.cmpEstadio === 'renglonInserting' || this.$store.state.cmpEstadio === 'renglonEditing'
    },
    showPrecBulM () {
      return this.dicPlu.stat_m && evParser(this.dicPlu.precbul_m, { type: 'evF' }) < evParser(this.dicPlu.prec_m, { type: 'evF' })
    },

    nroRenglonEditing () {
      return this.$store.state.nroRenglonEditing
    },
    dicRenglonEditing () {
      return this.$store.state.dicRenglonEditing
    },
    dicPlu () {
      return this.$store.state.dicPlu
    },

    title () {
      if (this.nroRenglonEditing === null) {
        return `Agregando renglón...`
      } else {
        return `Editando renglón ${this.nroRenglonEditing + 1}...`
      }
    },
    btnText () {
      if (this.nroRenglonEditing === null) {
        return 'agregar'
      } else {
        return 'confirmar'
      }
    },

  },
  methods: {
    numberFormat (n) {
      return evNumberFormat[2].format(n)
    },

    onChange () {
      if (this.lstPrsCant.length) {
        // Para cada item de la lstPrsCant cacula: cant = prs_cant * ...
        this.lstPrsCant = calcLstPrsCant(this.lstPrsCant, this.dicPlu.peso)

        // Sumariza para obtener el total equivalente en Kg
        this.cant = sumLstPrsCant(this.lstPrsCant)

      } else {
        this.cant = evParser(this.cant, { type: "evN" })
        if (this.cant <= 0) {
          this.cant = 0;
        }
      }
    },


    async resetAll () {
      // Nos aseguramos de refrescar los datos
      this.submiting = true
      await this.$store.dispatch('refreshDicPlu', { vm: this, cod: this.dicRenglonEditing.cod })
      this.submiting = false
      this.error = ''
      this.cant = this.dicRenglonEditing.cant
      this.lstPrsCant = this.dicRenglonEditing.lstPrsCant || []

    },

    cmdClose () {
      this.$store.dispatch('goMainSearching')
    },

    cmdAsentar () {
      // eslint-disable-next-line no-console
      // console.log(['plu-card-edit.cmdAsentar: cant=', this.cant])

      // Me aseguro que se valide
      this.onChange()

      if (this.cant && this.cant > this.dicPlu.stkdispo) {
        this.error = `Stock Disponible: ${this.dicPlu.stkdispo} ${this.dicPlu.stat_z ? 'Kg' : 'Unidades'}.`
        return
      }

      this.$store.dispatch(
        "cmdRenglonUpdate",
        {
          cod: this.dicPlu.cod,
          web_des: this.dicPlu.web_des,
          prec_m: this.dicPlu.prec_m,
          precbul_m: this.dicPlu.precbul_m,
          stat_m: this.dicPlu.stat_m,
          stat_z: this.dicPlu.stat_z,
          file_ni: this.dicPlu.file_ni,
          stkdispo: this.dicPlu.stkdispo,
          cant: this.cant,
          lstPrsCant: this.lstPrsCant,
        }
      )
      this.cmdClose()
    }

  }
}
</script>
