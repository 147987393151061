<template>
  <v-breadcrumbs :items="linkItems">
    <!--
  <v-breadcrumbs divider="/">
    <v-breadcrumbs-item
      v-for="(item, i) in linkItems"
      :key="i"
      :to="item.to"
      exact
    >
      {{ item.text }}
    </v-breadcrumbs-item>
  </v-breadcrumbs>
  -->
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'plu-menu-path',
  computed: {
    linkItems () {
      let items = [{ text: 'Categorias', to: { name: 'inicio' }, exact: true }]

      if (this.$store.state.dicGrpgrpdep.grpgrpdep) {
        items.push({
          text: this.$store.state.dicGrpgrpdep.des,
          to: {
            name: 'plu-main',
            query: { grpgrpdep: this.$store.state.dicGrpgrpdep.grpdep }
          },
          exact: true
        })
      }

      if (this.$store.state.dicGrpdep.grpdep) {
        items.push({
          text: this.$store.state.dicGrpdep.des,
          to: {
            name: 'plu-main',
            query: { grpdep: this.$store.state.dicGrpdep.grpdep }
          },
          exact: true
        })
      }

      if (this.$store.state.dicDep.dep) {
        items.push({
          text: this.$store.state.dicDep.des,
          to: {
            name: 'plu-main',
            query: { grpdep: this.$store.state.dicGrpdep.grpdep, dep: this.$store.state.dicDep.dep }
          },
          exact: true
        })
      }

      if (this.$store.state.dicMar.mar) {
        items.push({
          text: this.$store.state.dicMar.des,
          disabled: false,
          to: {
            name: 'plu-main',
            query: { grpdep: this.$store.state.dicGrpdep.grpdep, dep: this.$store.state.dicDep.dep, mar: this.$store.state.dicMar.mar }
          },
          exact: true

        })
      }
      return items
    }
  }
}
</script>
