<template>
  <v-container>
    <v-row justify="center">
      <v-col
        cols="12"
        md="4"
      >
        <v-card :disabled="submiting">
          <v-toolbar>
            <v-btn
              icon
              to="/"
              tabindex="-1"
            >
              <v-icon>mdi-arrow-left</v-icon>

            </v-btn>

            <v-toolbar-title>Cambiar Contraseña</v-toolbar-title>
          </v-toolbar>

          <form @submit.prevent="formVld">

            <v-container>
              <evu-field-text
                ref="old_passw"
                field="old_passwd"
                :form="form"
                :type="okEyeOffOld ? 'text' : 'password'"
                :append-outer-icon="okEyeOffOld ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-outer="okEyeOffOld = !okEyeOffOld"
                autofocus
              >
              </evu-field-text>
              <evu-field-text
                ref="new_passw"
                field="new_passwd"
                :form="form"
                :type="okEyeOffNew1 ? 'text' : 'password'"
                :append-outer-icon="okEyeOffNew1 ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-outer="okEyeOffNew1 = !okEyeOffNew1"
              >
              </evu-field-text>
              <evu-field-text
                ref="new_passw2"
                field="new_passwd2"
                :form="form"
                :type="okEyeOffNew2 ? 'text' : 'password'"
                :append-outer-icon="okEyeOffNew2 ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-outer="okEyeOffNew2 = !okEyeOffNew2"
              >
              </evu-field-text>

            </v-container>

          </form>

          <v-container class="text-center">

            <v-btn
              tabindex="1"
              @click="formVld"
              color="primary"
              rounded
              outlined
              :loading="submiting"
              class="mx-2"
            >Asentar</v-btn>

          </v-container>
        </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>


<script>
import { evAxio } from '@/evlib/utils/ev-axios'
import { EvForm } from '@/evlib/utils/ev-form'
import evuFieldText from '@/evlib/evu-components/evu-field-text'
export default {
  name: 'ev-change-password',
  components: {
    evuFieldText,
  },
  inject: [
    'vmApp'
  ],
  data () {
    const form = new EvForm({})
    form.initAll({
      // Metadatos de los fields
      modelos: {
        old_passwd: {
          label: 'Clave Actual',
          type: 'evC',
          width: 80,
          can_null: 0,
        },
        new_passwd: {
          label: ' Nueva Clave',
          type: 'evC',
          width: 80,
          can_null: 0,
        },
        new_passwd2: {
          label: 'Confirmar Nueva Clave',
          type: 'evC',
          width: 80,
          can_null: 0,
        }
      }
    })


    return {
      submiting: false,
      form,
      okEyeOffOld: false,
      okEyeOffNew1: false,
      okEyeOffNew2: false,
    }
  },
  /* eslint-disable */
  methods: {
    formVld () {
      if (!this.form.formVld()) {
        // this.form.fieldFocus('email', this.$refs)
        this.form.errorFocus(this.$refs)
        return
      }

      if (this.form.data.new_passwd !== this.form.data.new_passwd2) {
        // console.log('Las contraseñas no coinciden')
        this.$root.$evModal.showError('Las contraseñas deben ser iguales')
        return
      }

      this.submiting = true
      evAxio({
        vm: this,
        url: `/${this.vmApp.$root.evSessionId}/login/api/changePasswd`,
        params: {
          _token: this.$root.evToken,
          old_passwd: this.form.data.old_passwd,
          new_passwd: this.form.data.new_passwd,
        },
        nvlError: 1
      })
        .then((data) => {
          if (data.error_nro === 0) {
            this.$root.$evModal.showInfo('Contraseña actualizada con exito')
            this.$router.replace('/')
          } else {
            this.form.formSetErrors(data.errors)
            this.form.errorFocus(this.$refs)
          }
        })
        .finally(() => { this.submiting = false })
    },

  }

}
</script>
