
<template>
  <v-container fluid>
    <v-card
      tile
      flat
      :loading="loading"
    >

      <depo-select @select="onSelectDepo"></depo-select>
      <plu-card-edit></plu-card-edit>

      <v-toolbar flat>
        <v-spacer></v-spacer>

        <v-toolbar-title
          class="grey--text text--darken-1"
          v-show="$store.state.pluSearch"
        >
          {{ tituloSearch }}

        </v-toolbar-title>

        <v-toolbar-title
          class="grey--text text--darken-1"
          v-show="!$store.state.pluSearch"
        >
          {{ tituloSeccion }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="idShow='card'"
          v-show="!$vuetify.breakpoint.smAndDown"
        >
          <v-icon>mdi-apps</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="idShow='list'"
          v-show="!$vuetify.breakpoint.smAndDown"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

      </v-toolbar>

      <v-container fluid>
        <!-- desktop.Begin -->
        <v-row v-if="!$vuetify.breakpoint.smAndDown && idShow !== 'list'">
          <v-col
            xs="6"
            sm="4"
            md="4"
            lg="3"
            xl="2"
            v-for="plu in plus"
            :key="plu.cod"
          >

            <plu-card :dicPlu="plu"></plu-card>

          </v-col>
        </v-row>
        <!-- desktop.End -->

        <!-- movil.Begin -->
        <v-list
          three-line
          v-if="$vuetify.breakpoint.smAndDown || idShow === 'list'"
        >
          <template v-for="(plu, i) in plus">
            <plu-list-item
              :key="i"
              :dicPlu="plu"
              @click="onClickPlu(plu)"
            >
            </plu-list-item>
          </template>
        </v-list>
        <!-- movil.End -->

      </v-container>

    </v-card>

  </v-container>
</template>

<script>
import depoSelect from "@/components/depo-select"
import pluCardEdit from "@/components/plu-card-edit"
import pluCard from "@/components/plu-card"
import pluListItem from "@/components/plu-list-item"

export default {
  name: "pluMain",
  inject: [
    'vmApp'
  ],
  data () {
    return {
      loading: false,
      slcPlu: null,
      idShow: 'card', // 'card', 'list'
    };
  },
  components: {
    depoSelect,
    pluCardEdit,
    pluCard,
    pluListItem
  },
  computed: {
    plus () {
      // console.log(["plus()", this.$store.state.optionsPlu]);
      return this.$store.state.optionsPlu
    },
    tituloSearch () {
      return this.$store.getters.tituloSearch
    },
    tituloSeccion () {
      // console.log(['tituloSeccion ()=', this.$store.state.optionsPluGrpgrpdep])
      if (this.$store.state.optionsPluGrpgrpdep.length === 1) {
        return this.$store.state.optionsPluGrpgrpdep[0].des
      }
      return ''

    }
  },
  methods: {
    loadOptionsPlu () {
      this.loading = true
      // eslint-disable-next-line no-console
      // console.log(["pluMain.loadOptionsPlu()): disparo refreshOptionsPlu"])

      this.$store.dispatch(
        "refreshOptionsPlu",
        {
          vm: this,
          ...this.$route.query
        }
      )
        .finally(() => { this.loading = false })
    },

    onSelectDepo () {
      // Vuelvo a leer las opciones del plu para asegurarnos
      // que muestre el stock del deposito correspondiente
      this.loadOptionsPlu()

    }
  },

  watch: {
    $route () {
      // react to route changes...
      this.loadOptionsPlu()
    }
  },
  beforeRouteEnter (to, from, next) {
    // eslint-disable-next-line no-console
    // console.log(["pluMain.beforeRouteEnter(): Inicio"]);
    next(vm => {
      vm.loadOptionsPlu()
    })
  },

  mounted () {
    // eslint-disable-next-line no-console
    // console.log(["pluMain.mounted()"]);

    // 26-04-2021
    // -----v
    this.vmApp.drawerLeft = !this.$vuetify.breakpoint.smAndDown
    // -----^
    this.vmApp.drawerLeftShow = 'plu-menu'
  },

  beforeDestroy () {
    // eslint-disable-next-line no-console
    // console.log(["pluMain.beforeDestroy()"]);
    // this.vmApp.drawerLeft = false
    this.vmApp.drawerLeftShow = 'categoria-menu'
  }

};
</script>
