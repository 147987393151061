<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :color=options.color
      :timeout=options.timeout
      :left="options.x === 'left'"
      :right="options.x === 'right'"
      :top="options.y === 'top'"
      :bottom="options.y === 'bottom'"
    >
      {{ message }}
      <v-btn
        dark
        text
        @click="snackbar = false"
      >Cerrar</v-btn>
    </v-snackbar>

    <v-dialog
      v-model="wait"
      persistent
      :width="options.width"
    >
      <v-card class="text-center pt-2">
        <v-card-text
          class="red--text"
          v-show="!!options.html"
        >
          {{ message || 'Espere un momento...' }}
        </v-card-text>

        <v-card-text
          class="red--text"
          v-text="message || 'Espere un momento...'"
          v-show="!options.html"
        >
        </v-card-text>

        <v-card-text
          class="pt-2"
          v-html="options.html"
          v-show="options.html"
        ></v-card-text>

        <v-card-text>
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      :width="options.width"
    >
      <v-card>
        <v-toolbar
          dark
          :color="options.color"
          dense
          v-show="options.title "
        >
          <v-toolbar-title dark>{{ options.title }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text
          class="pt-2"
          v-text="message"
          v-show="message"
        ></v-card-text>

        <v-card-text
          class="pt-2"
          v-html="options.html"
          v-show="options.html"
        ></v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>

          <v-btn
            class="mx-2"
            @click="onOk"
            v-show="options.type !== 'conf'"
          >Continuar</v-btn>

          <v-btn
            color="primary"
            class="mx-2"
            dark
            @click="onOk"
            v-show="options.type === 'conf'"
          >Si</v-btn>
          <v-btn
            class="mx-2"
            @click="onCancel"
            v-show="options.type === 'conf'"
          >No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * evu-modal.vue
 * Ruben Valle
 * 15-08-2019
 * Componente "Modal-Dialog" de uso comun.
 *
 * Insertar <ev-modal> en <v-app>:
 * --------------------------------------------------
 * <template>
 *  <v-app>
 *    <evu-modal ref="evModal" />
 *    ...
 *  </v-app>
 * </template>
 * 
 * Metodos disponibles en toda la aplicacion
 * --------------------------------------------------
 * this.$root.$evModal.showWait()
 * this.$root.$evModal.hideWait()
 *
 * this.$root.$evModal.showSnackbar('Fin Proceso')
 *
 * this.$root.$evModal.showInfo('Fin del Proceso', {color: 'cyan'})
 * this.$root.$evModal.showConf('Asienta?').then(v => !v || this.submit())
 * 
 * this.$root.$evModal.showError('Plastico Invalido')
 * 
 * axios.post('/gra/public/api/lovTable/gra_prov/prov')
 * .then((response) => {...})
 * .catch((error) => {this.$root.$evModal.showAxioError(error)}) })
 *
 */

export default {
  name: 'evu-modal',
  data: () => ({
    snackbar: false,
    dialog: false,
    wait: false,
    resolve: null,
    reject: null,
    message: null,
    options: {}
  }),
  methods: {
    showWait (message, options) {
      this.options = {
        width: 300,
        ...options
      }
      this.message = message
      this.wait = true
    },

    hideWait () {
      this.wait = false
    },

    showSnackbar (message, options) {
      this.options = {
        color: 'accent',
        timeout: 6000,
        y: 'top',
        x: 'right',
        ...options
      }
      this.message = message
      this.snackbar = true
    },

    show (message, options) {
      this.message = message
      this.options = options
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    showConf (message, options) {
      return this.show(
        message,
        {
          title: 'Confirmar',
          color: 'accent',
          width: 300,
          type: 'conf',
          ...options
        })
    },
    showInfo (message, options) {
      return this.show(
        message,
        {
          title: 'Informa',
          color: 'primary',
          width: 300,
          type: 'info',
          ...options
        })
    },
    showError (message, options) {
      return this.show(
        message,
        {
          title: 'Error',
          color: 'error',
          width: 300,
          type: 'error',
          ...options
        })
    },
    showAxioError (axioError, options) {
      return this.showError(axioError.message + ' **** ' + (axioError.response ? axioError.response.data : ''), options)
    },
    onOk () {
      this.resolve(true)
      this.dialog = false
    },
    onCancel () {
      this.resolve(false)
      this.dialog = false
    }
  },

  created () {
    // console.log(['evu-modal.created'])
    this.$root.$evModal = this
  }
}
</script>
