import { render, staticRenderFns } from "./fin-compra.vue?vue&type=template&id=251231cf&"
import script from "./fin-compra.vue?vue&type=script&lang=js&"
export * from "./fin-compra.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCard,VCol,VContainer,VIcon,VRow,VToolbar,VToolbarTitle,VWindow,VWindowItem})
