// ./plugins/vuetify.js

// GGG Preferimos incluir los iconos dentro de la aplicacion 
// en lugar de usar en /build/index.htm:
// <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css">
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import es from 'vuetify/src/locale/es.ts'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { es },
    current: 'es'
  },

  theme: {
    themes: {
      light: {
        accent: '#E91E63',
      },
      dark: {
        accent: '#E91E63',
      },
    }
  }
});
