import Vue from "vue";
import Vuex from "vuex";

import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

Vue.use(Vuex);

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  // Estadio en el que se encuentra la emision del comprobante
  cmpEstadio: null,

  tiendaConf: {
    fpg_cta: "N", // 'S' => que el precio depende del cliente que este logueado
    rows: [],
  },

  reparDepoCant: 0, // Cantidad de depositos desde donde se puede entregar

  optionsReparCod: [], // [{repar_cod: 4002, des: 'Envio a S.M. Tuc. - CENTRO', envio_prec: 350}]
  optionsPrs: [], // [{prs, des}, {...}, ... ]

  optionsGrpgrpdep: [], // [{grpgrpdep, des}, {...}, ... ]
  optionsGrpdep: [], // [{grpdep, des, grpgrpdep}, {...}, ... ]
  optionsDep: [], // [{dep, des, grpdep}, {...}, ... ]

  optionsPlu: [], // [{cod, des, prec, file_ni}, {...}, ... ]
  optionsPluMar: [], // [{mar, des, cant}, {...}, ... ]
  optionsPluGrpgrpdep: [], // [{grpgrpdep, des, cant}, {...}, ... ]
  optionsPluGrpdep: [], // [{grpdep, des, cant}, {...}, ... ]
  optionsPluDep: [], // [{grpdep, dep, des, cant}, {...}, ... ]

  pluSearch: "", // Ultimo search de plus

  dicGrpgrpdep: {}, // {grpgrpdep, des}
  dicGrpdep: {}, // {grpdep, des}
  dicDep: {}, // {dep, des}
  dicMar: {}, // {mar, des}

  // ---------------------

  dicPlu: {}, // Informacion completa de un cod. Ver api pluInfo()
  nroRenglonEditing: null, // Indica el nro de renglon que se esta editando. NULL => que se esta agregando.
  dicRenglonEditing: {}, // Datos del renglon que se esta editando / agregando

  // ---------------------

  totImpM: 0, // Imp.Total del carrito sin envio ni financiacion
  totCant: 0, // Cant. de productos en el carrito

  dicWebFcb: {}, // {web_fcb, ...} Codigo de medio de cobro seleccionado
  dicReparCod: {}, // {repar_cod, ...} Codigo de reparto seleccionado
  dicReparData: {}, //{repar_loc, repar_loc_des, repar_dirc, ...} Datos p/ reparto

  dicCta: {}, // {cta, nom, dirc, ...}
  dicTot: { lst_web_fcb: [] }, // Totales calculados por el server {tot_merca, tot_envio, tot_finan, tot_per, tot_final}

  // Info del pedido en transito
  dicPedido: {
    ped_ni: null, // ped_ni recien emitido
    ped_next_func: null, // 'COB', 'END', 'DESCARTAR'
    ped_next_func_msg: null, // Ej: 'El Pedido Nro. 123 ya fue ANULADO'

    // renglones: [ {cod, des, prec, cant, imp, stkdispo, lstPrsCant: []}, {...}, ...]
    // if (dicPlu.web_lst_prs) {
    //     lstPrsCant = [{prs, des, web_coef, web_coef_calc, prs_cant, cant},..]
    //         // lstPrsCant.prs_cant: Cant. de Piezas o Blister
    //         // lstPrsCant.cant: Cant. de Kg. equivalente
    // }
    // PK=cod
    renglones: [],
  },
};

// A Vuex instance is created by combining the state, mutations, actions, and getters.
export default new Vuex.Store({
  getters,
  actions,
  mutations,
  state,
});
