<template>
  <div
    v-html="cont"
    class="grey--text  text--darken-2 evumd"
  >
  </div>
</template>

<script>
/**
 * evu-md-html.vue
 * Ruben Valle
 * 18-08-2020
 * Agrega estilo p/ html generado desde un marldown.
 **/

export default {
  name: 'evu-md-html',
  props: {
    cont: { type: String }, //  '<h1>Titulo</h1>'
  }
}
</script>


<style lang="scss">
.evumd h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 30px 0 10px;
}

.evumd table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  margin: 0 0 20px;
  width: 100%;
}

.evumd table thead {
  background-color: #f9f9f9;
}

.evumd tbody tr:hover {
  background: #f5f5f5;
}

.evumd caption,
td,
th {
  padding: 8px;
  vertical-align: middle;
}

.evumd table tr th {
  border-bottom: 3px solid #ddd;
  padding-bottom: 10px;
  background-color: var(--bg-color);
}

.evumd td,
th {
  border: 1px solid #ddd;
}

.evumd th {
  padding-bottom: 10px;
}

.evumd td {
  border-bottom-width: 1px;
}

.evumd pre {
  background-color: #fafafa !important;
  padding: 10px;
}

.evumd code {
  background-color: #fafafa !important;
  color: #f50057 !important;
}

.evumd blockquote {
  border-left: 4px solid #ddd;
  color: #666 !important;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0 15px;
  display: block !important;
}

.evumd blockquote > p {
  color: #666 !important;
  white-space: pre-line !important;
}

.evumd blockquote cite::before {
  content: "-";
  padding: 0 5px;
}
</style>
