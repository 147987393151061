import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'


// P/ Google Analytic
import VueGtag from "vue-gtag"


// P/ pwa tambien ver vue.config.js
// GGG-PWA-------------------------v
// import './registerServiceWorker'
// GGG-PWA--------------------------^

// GGG ----------------------------v
import store from './store'
// GGG ----------------------------^

Vue.config.productionTip = false


// Google: analyticID
// "G-C412Q0D19W": electronicavalle.com.ar
// "UA-197467856-1": alem500.com.ar
// ==========================================v
/*
Vue.use(
  VueGtag,
  {
    config: { id: "G-C412Q0D19W" }
  },
  router
)
*/
// Ver en App.vue: enableGtagPlugin
Vue.use(
  VueGtag,
  {
    bootstrap: false
  },
  router
)

// ==========================================^

new Vue({
  router,
  store, // GGG Si trabajamos con vuex
  vuetify,
  render: h => h(App)
}).$mount('#app')
