<template>
  <v-list dense>

    <v-subheader class="item-titulo primary--text text--accent-2">
      Categorías
    </v-subheader>
    <v-list-item
      v-for="grpgrpdep in $store.state.optionsGrpgrpdep"
      :key="grpgrpdep.grpgrpdep"
      :to="{name: 'plu-main', query: {grpgrpdep: grpgrpdep.grpgrpdep}}"
    >
      <v-list-item-subtitle> {{ grpgrpdep.des }} </v-list-item-subtitle>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "categoria-menu"
};
</script>
