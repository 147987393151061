<template>
  <v-card>
    <v-container>
      <template v-for="(item, i) in value">
        <v-text-field
          :key="i"
          :label="`Cantidad de ${item.des}:`"
          clearable
          outlined
          type="number"
          :value="item.prs_cant"
          @input="(v) => onInput(v, item)"
          @change="(v) => onChange(v, item)"
          :autofocus="!i"
          :messages="rtnMessage(item)"
        >
        </v-text-field>
      </template>
    </v-container>

  </v-card>
</template>


<script>
export default {
  name: 'plu-card-prs-cant-edit',
  props: {
    value: { type: Array }, // dicRenglonEditing.lstPrsCant
  },
  computed: {
  },
  methods: {
    rtnMessage (item) {
      if (item.web_coef_calc === 'P') {
        return `Peso aproximado: ${item.cant} Kg.`
      }
      if (item.web_coef_calc === 'G') {
        // return `Peso: ${item.cant} Kg.`
        return `Peso aproximado: ${item.cant} Kg.`
      }
      // item.web_coef_calc === 'U'
      return `Cantidad en UNIDADES: ${item.cant}`
    },
    onInput (v, item) {
      // console.log(['plu-card-prs-cant-edit: onInput, v=', v, 'item=', item])
      item.prs_cant = v
      this.$emit('input', [...this.value])
    },
    onChange () {
      // console.log(['plu-card-prs-cant-edit: onChange, v=', v, 'item=', item])
      this.$emit('change', [...this.value])
    }
  }
}
</script>