<template>
  <div class="d-flex align-center">
    <v-text-field
      dense
      rounded
      flat
      solo-inverted
      hide-details
      clearable
      prepend-inner-icon="mdi-magnify"
      label="Buscar"
      v-model="search"
      @change="onSearch"
    ></v-text-field>

  </div>

</template>

<script>
import { evParser } from "@/evlib/utils/ev-modelo"
export default {
  name: 'plu-search',
  data () {
    return {
      search: ''
    }
  },
  methods: {
    onSearch () {
      // eslint-disable-next-line no-console
      // console.log(['onSearch()'])
      const query = { search: this.search }

      if (evParser(this.search, { type: 'evN' }) !== null) {
        // Reseteamos p/ cuando consultamos con un scanner
        this.search = ''
      }

      // El cambio de URL sera capturada en plu-main.vue mediante
      // watch: { $route () { this.loadOptionsPlu() }) } }

      // El catch() es para evitar que salga en el log el mensaje:
      //  Error: Avoided redundant navigation to current location:
      this.$router.push({ name: "plu-main", query })
        .catch(() => { })

    }
  }
}
</script>


