/* ev-tables.js
   Ruben Valle
   Ver. 1.0: 27-09-2017
   -------------------------
   Funciones para el manejo de tables
*/

// Ej:
// export const form1Modelos = { prov: {...evTableGetModelo(tabGraLoc, 'prov'), ... }, ...}
export const evTableGetModelo = (table, name) => {
    const modelo = table.fields.find((modelo) => { return modelo.name === name })

    // console.log(['modelo=', modelo])
    if (!modelo) {
        // eslint-disable-next-line 
        console.error(['ERROR en evTableGetModelo() no se encontro el field.name=', name])
    }
    return modelo
}

// Ej: export const locModelos = evTableGetModelos(tabGraLoc)
export const evTableGetModelos = (table) => {
    return evGetModelos(table.fields)
}

// Retorna "modelos" en base a la definicios de "fields"
// "fields" puede venir de evTableGetModelos(table) o desde el server.api "getParamFields".
export const evGetModelos = (fields) => {
    const modelos = {}
    // fields.forEach((modelo) => { modelos[modelo.name] = modelo })
    for (const modelo of fields) {
        modelos[modelo.name] = modelo
    }
    return modelos
}
