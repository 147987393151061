<!--
evu-app-user-menu.vue
Ruben Valle
Menu p/ usar en el toolbar de App.vue
Ver: evu-app-user-drawer.vue
------------------------------------
Ver 1.0 15-08-2018
-->

<template>
  <div>

    <v-btn
      tabindex="-1"
      text
      @click="cmdLogin"
      v-if="!user"
    >
      <span class="hidden-sm-and-down">
        Ingresar
      </span>

      <v-icon
        dark
        right
        class="hidden-md-and-up"
      >mdi-location-enter</v-icon>

    </v-btn>

    <v-menu
      offset-y
      v-else
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          text
          style="text-transform: capitalize;"
        >
          <v-icon>mdi-account-outline</v-icon>
          {{ userDes }}
        </v-btn>

      </template>
      <v-list>
        <v-list-item @click="cmdLogout">
          <v-list-item-content>
            <v-list-item-title class="red--text">
              Cerrar Sesión
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="cmdChangePassword">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Cambiar Contraseña
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>


<script>
export default {
  name: 'evu-app-user-menu',
  props: {
    user: { default: '' },
    userDes: { type: String, default: 'userDes1' },
  },

  methods: {
    // 02-10-2020
    // Borrar
    /*
    cmdLogin () {
      // Ver ev-session.js
      if (this.$root.evSessionType) {
        this.$router.replace({ path: '/login', query: { nextUrl: '/' } })
      } else {
        window.location = `/${this.vmApp.$root.evSessionId}/login/index/login`
      }
    },
    cmdLogout () {
      // Ver ev-session.js
      if (this.$root.evSessionType) {
        // this.$router.replace({ path: '/logout', query: { nextUrl: '/' } })
        this.$router.replace('/logout')
      } else {
        window.location = `/${this.$root.evSessionId}/login/index/logout`
      }
    },
    cmdChangePassword () {
      // Ver ev-session.js
      if (this.$root.evSessionType) {
        // this.$router.replace({ path: '/change_password', query: { nextUrl: '/' } })
        this.$router.replace('/change_password')
      } else {
        window.location = `${this.vmApp.$root.evSessionId}/index/change_password`
      }
    }

    */
    cmdLogin () {
      // Ver ev-session.js
      this.$router.replace({ path: '/login', query: { nextUrl: '/' } })
    },
    cmdLogout () {
      // Ver ev-session.js
      this.$router.replace('/logout')
    },

    cmdChangePassword () {
      this.$router.replace('/change_password')
    }
  }
}
</script>
