<template>
  <v-carousel cycle class="hidden-sm-and-down">
    <v-carousel-item
      v-for="(item, i) in lstCarousel"
      :key="i"
      :src="`/drive/file/index/0/${item.file_ni}`"
      reverse-transition="fade"
      transition="fade"
      :href="item.cod ? '#' : ''"
      @click.native="onSelectPlu(item)"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "lst-carousel",
  props: {
    lstCarousel: { type: Array, required: true } // [{file_ni: 2, cod: 334}, ...]
  },
  data: () => ({
    submiting: false,
  }),
  methods: {
    onSelectPlu (item) {
      if (item.cod) {
        this.submiting = true

        this.$store.dispatch(
          'cmdSelectPlu',
          {
            vm: this,
            cod: item.cod
          }
        )
          .finally(() => {
            this.submiting = false
          })
          return
      }

      if (item.grpgrpdep) {
        this.$router.replace({ path: '/plu-main', query: { grpgrpdep: item.grpgrpdep } })
        return
      }
    },
  }
}
</script>

<!--
Tamano de imagenes p/ banner:
Las medidas sugeridas: 1320 x 440
Pero que el contenido no llegue hasta el borde.
Porque dependiendo de la relación de aspecto de la pantalla se puede perder  parte de los bordes
-->
<style lang="scss" scoped>
.v-carousel {
  height: 440px !important;

  @media (max-width: 600px) {
    height: 280px !important;
  }
}
</style>