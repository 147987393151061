<template>
  <div>

    <v-container>
      <p><span class="text-subtitle-2"> Tipo de Entrega: </span><span class="grey--text text--darken-1">{{ reparTipoDes }}</span></p>

      <div v-show="dicReparCod.repar_tipo==='D'">
        <v-switch
          v-model="reparDircIdemFact"
          label="Enviar a la dirección de facturación"
        ></v-switch>

        <v-card
          color="grey lighten-4"
          v-show="reparDircIdemFact"
        >
          <v-subheader> Su dirección de facturación es:</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ dicCta.nom}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ dicCta.dirc}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{dicCta.cp }} - {{ dicCta.loc_des}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ dicCta.prov_des}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ dicCta.tel_area1}} - {{ dicCta.tel_nro1}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ dicCta.tel_area2}} - {{ dicCta.tel_nro2}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-card>

        <v-card
          color="grey lighten-4"
          v-show="!reparDircIdemFact"
        >
          <v-subheader> Indique dirección de entrega:</v-subheader>

          <v-container>
            <evu-field-autocomplete
              ref="repar_loc"
              field="repar_loc"
              :form="form"
              :items="optionsReparLoc"
              :loading="locLoading"
            ></evu-field-autocomplete>

            <evu-field-text
              ref="repar_cp"
              field="repar_cp"
              :form="form"
            ></evu-field-text>

            <evu-field-text
              ref="repar_dirc"
              field="repar_dirc"
              :form="form"
            ></evu-field-text>

            <br />
            <span class="subtitle-2 primary--text">Teléfonos:</span>
            <v-card color="grey lighten-5">
              <v-container>
                <v-row>
                  <v-col cols="5">
                    <evu-field-text
                      ref="repar_tel_area1"
                      field="repar_tel_area1"
                      :form="form"
                      prefix="+54 - 0"
                    ></evu-field-text>
                  </v-col>
                  <v-col cols="7">
                    <evu-field-text
                      ref="repar_tel_nro1"
                      field="repar_tel_nro1"
                      :form="form"
                      prefix="15"
                    ></evu-field-text>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="5">
                    <evu-field-text
                      ref="repar_tel_area2"
                      field="repar_tel_area2"
                      :form="form"
                      prefix="+54 - 0"
                    ></evu-field-text>
                  </v-col>
                  <v-col cols="7">
                    <evu-field-text
                      ref="repar_tel_nro2"
                      field="repar_tel_nro2"
                      :form="form"
                    ></evu-field-text>
                  </v-col>

                </v-row>
              </v-container>

            </v-card>
          </v-container>
        </v-card>

      </div>
      <v-container class="text-center">
        <v-btn
          @click="continuar"
          outlined
          rounded
          color="primary"
          :loading="loading"
        >Continuar</v-btn>

      </v-container>

    </v-container>
  </div>
</template>

<script>
import { evAxio } from '@/evlib/utils/ev-axios'
// import { evForm } from '@/evlib/utils/ev-modelo'
import { EvForm } from '@/evlib/utils/ev-form'

import evuFieldText from '@/evlib/evu-components/evu-field-text'
import evuFieldAutocomplete from '@/evlib/evu-components/evu-field-select'
import { evTableGetModelo } from '@/evlib/utils/ev-tables'
import tabCcdPed from 'evvue@/ccd/conf/tables/ccd_ped.json'

export default {
  name: "fin-dirc-entrega",
  components: {
    evuFieldText,
    evuFieldAutocomplete
  },
  props: {
    loading: { type: Boolean }
  },
  data () {
    const modelos = {
      repar_loc: { ...evTableGetModelo(tabCcdPed, 'repar_loc'), can_null: 0, options: [] },

      repar_cp: { ...evTableGetModelo(tabCcdPed, 'repar_cp'), can_null: 0, is_upper: 1 },
      repar_dirc: { ...evTableGetModelo(tabCcdPed, 'repar_dirc'), can_null: 0, is_upper: 1, label: 'Dirección' },

      repar_tel_area1: { ...evTableGetModelo(tabCcdPed, 'repar_tel_area1'), width: 4, label: 'Móvil-Area' },
      repar_tel_nro1: { ...evTableGetModelo(tabCcdPed, 'repar_tel_nro1'), width: 8, label: 'Móvil-Nro.' },

      repar_tel_area2: { ...evTableGetModelo(tabCcdPed, 'repar_tel_area2'), width: 4, label: 'Fijo-Area' },
      repar_tel_nro2: { ...evTableGetModelo(tabCcdPed, 'repar_tel_nro2'), width: 8, label: 'Fijo-Nro.' },

    }

    const form = new EvForm({})
    form.initAll({modelos: modelos})

    return {
      reparDircIdemFact: true,
      locLoading: false,
      optionsReparLoc: [],
      // form: evForm(modelos),
      form
    }
  },
  computed: {
    dicCta () {
      return this.$store.state.dicCta
    },
    dicReparCod () {
      return this.$store.state.dicReparCod
    },
    reparTipoDes () {
      return this.$store.getters.reparTipoDes
    }
  },

  watch: {
    dicReparCod () {
      this.getOptionsReparLoc()
    }
  },
  methods: {
    getOptionsReparLoc () {
      this.optionsReparLoc = []
      this.form.fieldSetData('repar_loc', null)
      if (this.dicReparCod.repar_cod) {
        this.locLoading = true
        evAxio({
          vm: this,
          url: '/ccd_cliente/tienda/api/optionsReparCodLoc',
          params: {
            repar_cod: this.dicReparCod.repar_cod
          },
          nvlError: 0
        })
          .then((data) => {
            this.optionsReparLoc = data.options
          })
          .finally(() => {
            this.locLoading = false
          })
      }
    },

    continuar () {
      if (this.reparDircIdemFact && this.optionsReparLoc.findIndex(v => v.value === this.dicCta.loc) == -1) {
        this.$root.$evModal.showInfo(`La localidad "${this.dicCta.loc_des}" no está permitida para el "Envio a Domicilio-Tipo: ${this.dicReparCod.des}"`)
        return
      }

      if (this.dicReparCod.repar_tipo === 'C' || this.reparDircIdemFact) {
        this.$store.commit('SET_DIC_REPAR_DATA', {})
        this.$emit('input')
        return
      }

      if (!this.form.formVld()) {
        this.form.errorFocus(this.$refs)
        return
      }
      if (!this.form.data.repar_tel_nro1 && !this.form.data.repar_tel_nro2) {
        this.form.fieldSetError('repar_tel_nro1', 'Debe ingresar por lo menos un número de teléfono.')
        return
      }
      const repar_loc_des = (this.optionsReparLoc.find(v => v.value === this.form.data.repar_loc) || {}).text

      this.$store.commit('SET_DIC_REPAR_DATA', { repar_loc_des: repar_loc_des, ...this.form.data })
      this.$emit('input')
    }

  },

  mounted () {
    this.getOptionsReparLoc()
  }

}
</script>
