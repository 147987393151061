<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <span class="text-h6 grey--text text--darken-1">Finalizando la Compra</span>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        col="12"
        md="6"
      >
        <v-card
          :disabled="submiting"
          :loading="submiting"
        >
          <v-toolbar
            dense
            dark
            flat
            color="secondary"
          >
            <v-btn
              icon
              @click="anterior"
            >
              <v-icon>mdi-arrow-left</v-icon>

            </v-btn>

            <v-toolbar-title>
              {{ currentTitle }}
            </v-toolbar-title>
          </v-toolbar>

          <v-window v-model="step">
            <v-window-item :value="1">
              <fin-slc-repar-cod
                :loading="submiting"
                @input="submitSlcReparCod"
              ></fin-slc-repar-cod>
            </v-window-item>

            <v-window-item :value="2">
              <fin-dirc-entrega
                :loading="submiting"
                @input="submitDircEntrega"
              ></fin-dirc-entrega>
            </v-window-item>

            <v-window-item :value="3">
              <fin-slc-web-fcb
                :loading="submiting"
                @input="submitSlcWebFcb"
              ></fin-slc-web-fcb>
            </v-window-item>

            <v-window-item :value="4">
              <fin-confirmar
                :loading="submiting"
                @input="submitConfirmar"
              ></fin-confirmar>

            </v-window-item>

          </v-window>

        </v-card>

        <v-container class="text-center">
          <v-btn
            text
            color="info"
            :disabled="submiting"
            to="/"
          >Seguir Comprando</v-btn>
        </v-container>

      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import { evAxio } from '@/evlib/utils/ev-axios'
import finSlcReparCod from "@/components/fin-slc-repar-cod"
import finSlcWebFcb from "@/components/fin-slc-web-fcb"
import finDircEntrega from "@/components/fin-dirc-entrega"
import finConfirmar from "@/components/fin-confirmar"
export default {
  name: "fin-compra",
  components: {
    finSlcReparCod,
    finSlcWebFcb,
    finDircEntrega,
    finConfirmar
  },
  data () {
    return {
      submiting: false,
      step: 1,
    }
  },
  inject: ["vmApp"],
  computed: {
    currentTitle () {
      switch (this.step) {
        case 1: return 'Confirme tipo de entrega'
        case 2: return 'Indique dirección de entrega'
        case 3: return 'Seleccione medio de pago'
        case 4: return 'Controle y Confirme'
        default: return '???????????'

      }
    },
    dicReparCod () {
      return this.$store.state.dicReparCod
    },

  },
  methods: {
    getCtaInfo () {
      evAxio({
        vm: this,
        url: '/ccd_cliente/login/api/getCtaInfo',
        params: {
          _token: this.$root.evToken,
        },
        nvlError: 0
      })
        .then((data) => {
          this.$store.commit('SET_DIC_CTA', data.dic_cta)
        })
    },

    anterior () {
      if (this.step <= 1) {
        this.$router.replace('/')
      } else {
        this.resetPed()
        this.step = 1
      }
    },
    resetPed () {
      // this.$store.commit('SET_DIC_REPAR_COD', {})
      this.$store.commit('SET_DIC_REPAR_DATA', {})
      this.$store.commit('SET_DIC_WEB_FCB', {})
      this.$store.commit('SET_LST_COD_SIN_STK', [])
    },

    /*
    submitSlcReparCod () {
      if (this.dicReparCod.repar_tipo === 'C') {
        // Si retira el cliente => no es necesario preguntar por la direccion
        this.step += 1
      }
      this.step += 1
      this.submitPed('C', null, null)
    },
    */

    submitSlcReparCod () {
      this.$store.commit('SET_LST_COD_SIN_STK', [])
      this.submitPed('S', null, null)
    },

    submitDircEntrega () {
      this.step += 1
      this.submitPed('C', null, null)
    },
    submitSlcWebFcb () {
      this.step += 1
      this.submitPed('C', null, null)
    },

    submitConfirmar (v) {
      // this.step += 1
      this.submitPed('A', v.aclaracion, v.sustituir)
    },

    async submitPed (modo, aclaracion, sustituir) {
      // console.log(['submitPed(): modo=', modo, 'aclaracion=:', aclaracion, 'sustituir=', sustituir])
      this.submiting = true
      if (modo === 'A') {
        this.$root.$evModal.showWait('Enviando pedido')
      }

      const state = this.$store.state
      const data = await evAxio({
        vm: this,
        url: '/ccd_cliente/tienda/api/submitPed',
        params: {
          _token: this.$root.evToken,
          renglones: this.$store.state.dicPedido.renglones,
          modo: modo, // 'C'= Solo Calcula, 'A'=Asienta pedido
          web_fcb: state.dicWebFcb.web_fcb, // Codigo de medio de cobro seleccionado
          repar_cod: state.dicReparCod.repar_cod, // Codigo de reparto seleccionado 
          aclaracion: aclaracion,
          sustituir: sustituir,
          ...state.dicReparData, // //{repar_loc, repar_dirc, ...} Datos p/ reparto
        },
        nvlError: 99
      })

      // console.log(['data=', data])

      this.submiting = false
      if (modo === 'A') {
        this.$root.$evModal.hideWait()
      }

      if (data.error_nro) {
        await this.$root.$evModal.showError(data.error_txt)
        if (data.error_nro === 3) {
          this.$router.replace('/logout')
        }
        return
      }

      this.$store.commit('SET_DIC_TOT', data.dic_tot)

      if (modo === 'S') {

        if (data.lst_cod_sin_stk.length) {
          // Si hay codigos sin stock suficiente, entonces redirijo p/ que corrija
          this.$store.commit('SET_LST_COD_SIN_STK', data.lst_cod_sin_stk)
          this.vmApp.drawerRight = true
        } else {
          // Seguimos normalmente
          if (this.dicReparCod.repar_tipo === 'C') {
            // Si retira el cliente => no es necesario preguntar por la direccion
            this.step += 2
          } else {
            this.step += 1
          }
        }
      } else if (modo === 'A') {
        // Guarda datos del pedido recien emitido
        this.$store.commit(
          'SET_DIC_PEDIDO',
          {
            ped_ni: data.ped_ni,
            ped_next_func: data.ped_next_func,
            ped_next_func_msg: data.ped_next_func_msg
          }
        )
        this.$router.replace('/fin-confirmacion')

        // -------------v
        evAxio({
          vm: this,
          url: '/ccd_cliente/tienda/api/sendMailPed',
          params: { ped_ni: data.ped_ni }
        })
        // -------------^

        /*
        // eslint-disable-next-line no-console
        // console.log(['data.mpDataPreference=', data.mpDataPreference])
        if (data.mpDataPreference) {
          // Redirigimos para registrar el pago em Mercado Pago
          window.open(data.mpDataPreference.init_point)
        }
        */

      }
    },

  },

  beforeRouteEnter (to, from, next) {
    // eslint-disable-next-line no-console
    // console.log(['fin-compra.beforeRouteEnter'])
    next(vm => {
      // eslint-disable-next-line no-console
      // console.log(['fin-compra.beforeRouteEnter.next'])

      // Me aseguro de que este logueado
      if (vm.$root.evUser) {
        vm.resetPed()

        vm.$store.commit('SET_DIC_CTA', {})
        vm.getCtaInfo()

        if (vm.$store.state.dicPedido.renglones.length) {
          // Recalcula totales
          vm.submitPed('C', null, null)
        } else {
          // El carrito esta vacio
          vm.$router.replace({ path: '/' })
        }

      } else {
        // Debe estar logueado
        // console.log(['Debe estar logueado: to.fullPath=', to.fullPath])
        vm.$router.replace({ path: '/login', query: { nextUrl: to.fullPath } })
      }

    })
  }

}
</script>
