<template>
  <v-menu
    offset-y
    max-height="500"
  >

    <template v-slot:activator="{ on, attrs }">

      <v-btn
        text
        v-bind="attrs"
        v-on="on"
      >
        Categorías
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <categoria-menu></categoria-menu>

  </v-menu>
</template>

<script>
import categoriaMenu from "@/components/categoria-menu"
export default {
  name: "appbar-categoria-menu",
  components: {
    categoriaMenu,
  }
}
</script>
