<template>
  <v-container fluid>
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-card :loading="submiting">
          <v-toolbar>
            <v-btn
              icon
              to="/"
              tabindex="-1"
            >
              <v-icon>mdi-arrow-left</v-icon>

            </v-btn>

            <v-toolbar-title> Iniciar Sesión</v-toolbar-title>
          </v-toolbar>

          <form @submit.prevent="formVld">

            <v-container>
              <evu-field-text
                ref="user"
                field="user"
                :form="form"
                :label="`Su ${userLabel}`"
                autofocus
                type="email"
                prepend-icon="mdi-account"
              >
              </evu-field-text>
              <evu-field-text
                ref="passw"
                field="passwd"
                :form="form"
                prepend-icon="mdi-key"
                :type="okEyeOff ? 'text' : 'password'"
                :append-outer-icon="okEyeOff ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-outer="okEyeOff = !okEyeOff"
              >
              </evu-field-text>
            </v-container>

          </form>

          <v-container class="text-center">
            <v-btn
              tabindex="1"
              @click="formVld"
              color="primary"
              rounded
              outlined
              :loading="submiting"
              class="mx-2"
            >iniciar sesión</v-btn>
          </v-container>

        </v-card>
      </v-col>

    </v-row>

    <v-container
      class="text-center"
      v-if="vmApp.$root.evSessionCanResetPasswd"
    >
      <span class="subtitle-1 grey--text text--darken-1">Olvidó su contraseña?</span><br />
      <v-btn
        text
        color="info"
        to="/reset_password"
      >recuperar clave</v-btn>
    </v-container>

    <v-container
      class="text-center"
      v-if="vmApp.$root.evSessionCanCreateUser"
    >
      <span class="subtitle-1 grey--text text--darken-1"> Primera vez que ingresa?</span><br />
      <v-btn
        text
        color="info"
        to="/create_user"
        class="mx-2"
      >crear una cuenta</v-btn>
    </v-container>
  </v-container>
</template>


<script>
import { evAxio } from '@/evlib/utils/ev-axios'
import { evSessionWrite } from '@/evlib/utils/ev-session'
import { EvForm } from '@/evlib/utils/ev-form'
import evuFieldText from '@/evlib/evu-components/evu-field-text'
export default {
  name: 'ev-login',
  components: {
    evuFieldText,
  },
  inject: [
    'vmApp'
  ],
  data () {
    // Metadatos de los fields
    const form = new EvForm({})
    form.initAll({
      modelos: {
        user: {
          label: 'Su Operador', // 'Usuario',
          type: 'evC',
          width: 80,
          can_null: 0
        },
        passwd: {
          label: 'Su Clave',
          type: 'evC',
          width: 80,
          can_null: 0,
        }
      }


    })
    return {
      okEyeOff: false,
      submiting: false,
      form
    }
  },
  computed: {
    userLabel () {
      // eslint-disable-next-line no-console
      // console.log(['ev-login-userLabel=', this.$root.evSessionUserLabel])
      return this.$root.evSessionUserLabel
    }
  },
  /* eslint-disable */
  methods: {

    formVld () {
      // console.log(['ev-login.formVld()'])

      if (!this.form.formVld()) {
        // this.form.fieldFocus('email', this.$refs)
        this.form.errorFocus(this.$refs)
        return
      }

      this.submiting = true
      // const url = `/${this.vmApp.$root.evSessionId}/login/api/getJwtToken`
      const url = `/${this.vmApp.$root.evSessionId}/login/api/login`
      // console.log(['ev-login url=', url])

      evAxio({
        vm: this,
        url: url,
        params: { sessionType: this.$root.evSessionType, ...this.form.data },
        nvlError: 1
      })
        .then((data) => {
          if (data.error_nro === 0) {
            // console.log(['data.token=', data.token])
            // Guarda token en $root y localStorage
            // evSessionWrite(this.vmApp, data)
            evSessionWrite(this.vmApp, data.dic_token)

            this.$emit('loggedIn')

            if (this.$route.query.nextUrl) {
              this.$router.replace(this.$route.query.nextUrl)
            }

          } else {
            this.form.formSetErrors(data.errors)
            this.form.errorFocus(this.$refs)
          }
        })
        .finally(() => { this.submiting = false })
    },

  }
}
</script>
