<template>
  <v-container>
    <p>submiting= {{ submiting }}</p>
    <v-row justify="center">
      <v-col
        cols="12"
        md="5"
      >
        <v-card :disabled="submiting">
          <v-toolbar
            color="primary"
            dark
          >
            <v-btn
              icon
              to="/"
            >
              <v-icon>mdi-arrow-left</v-icon>

            </v-btn>

            <v-toolbar-title>Crear una Cuenta</v-toolbar-title>
          </v-toolbar>

          <!-- ################################################## 
               formMail.Begin 
               ################################################## -->
          <div v-show="idShow1 === 'formMail'">
            <form @submit.prevent="formMailVld">

              <v-container>
                <p class="subtitle-1 grey--text text--darken-1">
                  Por favor introduzca su dirección de email.
                </p>
                <evu-field-text
                  ref="email"
                  field="email"
                  :form="formMail"
                  autofocus
                  type="email"
                >
                </evu-field-text>

              </v-container>

            </form>

            <v-container class="text-center">

              <v-btn
                @click="formMailVld"
                color="primary"
                :loading="submiting"
                class="mx-2"
                text
              >continuar</v-btn>

            </v-container>
          </div>
          <!-- ################################################## 
               formMail.End 
               ################################################## -->

          <!-- ################################################## 
               form.End 
               ################################################## -->
          <div v-show="idShow1 === 'form'">

            <form @submit.prevent="formVld">

              <v-container>
                <p class="subtitle-1 grey--text text--darken-1">
                  <strong>Su eMail: </strong> {{formMail.data.email}}
                </p>

                <span class="subtitle-2 primary--text">Datos Fiscales:</span>
                <v-card>

                  <v-container>
                    <v-radio-group
                      v-model="okCF"
                      label="Es usted Consumidor Final?"
                      @change="onChangeOkCF"
                    >
                      <v-radio
                        label="Si"
                        value="S"
                      ></v-radio>
                      <v-radio
                        label="No"
                        value="N"
                      ></v-radio>
                    </v-radio-group>

                    <evu-field-text
                      ref="dni"
                      field="dni"
                      :form="form"
                      v-show="okCF === 'S'"
                      autofocus
                    ></evu-field-text>
                    <evu-field-radio
                      ref="sexo"
                      field="sexo"
                      :form="form"
                      v-show="okCF === 'S'"
                    >
                    </evu-field-radio>

                    <evu-field-select
                      ref="tiva"
                      field="tiva"
                      :form="form"
                      v-show="okCF === 'N'"
                      lovQueryType="mounted"
                    >
                    </evu-field-select>
                    <evu-field-text
                      ref="cuit"
                      field="cuit"
                      :form="form"
                      v-show="okCF === 'N'"
                    ></evu-field-text>

                    <evu-field-text
                      ref="apellido"
                      field="apellido"
                      :form="form"
                      autofocus
                    ></evu-field-text>

                    <evu-field-text
                      ref="nombre"
                      field="nombre"
                      :form="form"
                    ></evu-field-text>

                    <evu-field-text
                      ref="nacfec"
                      field="nacfec"
                      :form="form"
                    ></evu-field-text>

                  </v-container>
                </v-card>

                <br />
                <span class="subtitle-2 primary--text">Dirección:</span>
                <v-card>
                  <v-container>
                    <evu-field-select
                      ref="prov"
                      field="prov"
                      :form="form"
                      lovQueryType="mounted"
                    >
                    </evu-field-select>

                    <evu-field-autocomplete
                      ref="loc"
                      field="loc"
                      :form="form"
                      lovQueryType="sync"
                      :lovParams="locLovParams"
                      :disabled="!prov"
                    ></evu-field-autocomplete>

                    <!--
                    <evu-field-autocomplete
                      ref="zona"
                      field="zona"
                      :form="form"
                      lovQueryType="mounted"
                    ></evu-field-autocomplete>
                    -->

                    <evu-field-text
                      ref="cp"
                      field="cp"
                      :form="form"
                    ></evu-field-text>

                    <evu-field-text
                      ref="dirc"
                      field="dirc"
                      :form="form"
                    ></evu-field-text>

                    <evu-field-text
                      ref="dirc_memo"
                      field="dirc_memo"
                      :form="form"
                      placeholder="De ser necesario indique cómo se llega a la dirección."
                    ></evu-field-text>
                  </v-container>
                </v-card>

                <br />
                <span class="subtitle-2 primary--text">Teléfonos:</span>
                <v-card>
                  <v-container>
                    <v-row>
                      <v-col cols="5">
                        <evu-field-text
                          ref="tel_area1"
                          field="tel_area1"
                          :form="form"
                          prefix="+54 - 0"
                        ></evu-field-text>
                      </v-col>
                      <v-col cols="7">
                        <evu-field-text
                          ref="tel_nro1"
                          field="tel_nro1"
                          :form="form"
                          prefix="15"
                        ></evu-field-text>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="5">
                        <evu-field-text
                          ref="tel_area2"
                          field="tel_area2"
                          :form="form"
                          prefix="+54 - 0"
                        ></evu-field-text>
                      </v-col>
                      <v-col cols="7">
                        <evu-field-text
                          ref="tel_nro2"
                          field="tel_nro2"
                          :form="form"
                        ></evu-field-text>
                      </v-col>
                    </v-row>

                  </v-container>
                </v-card>
              </v-container>

            </form>

            <v-container class="text-center">

              <v-btn
                @click="formVld"
                color="primary"
                :loading="submiting"
                class="mx-2"
              >crear cuenta</v-btn>

            </v-container>
          </div>
          <!-- ################################################## 
               form.End 
               ################################################## -->

        </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>


<script>
import { evAxio } from '@/evlib/utils/ev-axios'
// import { evForm, evDbS } from '@/evlib/utils/ev-modelo'

import { evDbS } from '@/evlib/utils/ev-modelo'
import { EvForm } from '@/evlib/utils/ev-form'

import evuFieldText from '@/evlib/evu-components/evu-field-text'
import evuFieldRadio from '@/evlib/evu-components/evu-field-radio'
import evuFieldSelect from '@/evlib/evu-components/evu-field-select'
import evuFieldAutocomplete from '@/evlib/evu-components/evu-field-autocomplete'
import { evTableGetModelo } from '@/evlib/utils/ev-tables'
import tabGraPers from 'evvue@/gra/conf/tables/gra_pers.json'
import tabGraPerstel from 'evvue@/gra/conf/tables/gra_perstel.json'
import tabGraProv from 'evvue@/gra/conf/tables/gra_prov.json'
export default {
  name: 'ev-create-user',
  components: {
    evuFieldText,
    evuFieldRadio,
    evuFieldSelect,
    evuFieldAutocomplete
  },
  inject: [
    'vmApp'
  ],
  data () {
    // Metadatos de los fields
    const modelosEmail = {
      email: {
        label: 'Correo Electrónico',
        type: 'evC',
        width: 80,
        can_null: 0,
        is_lower: 1,
        is_email: 1,
      },
    }

    const modelos = {
      dni: {
        label: 'DNI',
        type: 'evN',
        width: 11,
        can_null: 1,
        vlds: [this.vldDniNull]
      },
      sexo: {
        ...evTableGetModelo(tabGraPers, 'sexo'),
        label: 'Sexo (El que figura en su DNI)',
        default: null,
        can_null: 1,
        vlds: [this.vldDniNull]
      },

      cuit: {
        ...evTableGetModelo(tabGraPers, 'cuit'),
        label: 'CUIT',
        can_null: 1,
        vlds: [this.vldCuitNull]
      },

      tiva: { ...evTableGetModelo(tabGraPers, 'tiva'), options: [], default: 1, can_null: 0, label: 'Tipo de inscripción en IVA' },

      nacfec: { ...evTableGetModelo(tabGraPers, 'nacfec'), can_null: 1 },
      apellido: {
        type: 'evC',
        label: 'Apellido',
        width: 40,
        is_upper: 1,
        can_null: 0
      },
      nombre: {
        type: 'evC',
        label: 'Nombre',
        width: 40,
        is_upper: 1,
        can_null: 1
      },


      prov: { ...evTableGetModelo(tabGraProv, 'prov'), can_null: 0, options: [] },
      loc: { ...evTableGetModelo(tabGraPers, 'loc'), can_null: 0, options: [] },

      // zona: { ...evTableGetModelo(tabGraPers, 'zona'), can_null: 1, options: [] },

      cp: { ...evTableGetModelo(tabGraPers, 'cp'), can_null: 0, is_upper: 1 },
      dirc: { ...evTableGetModelo(tabGraPers, 'dirc'), can_null: 0, is_upper: 1 },
      dirc_memo: {
        ...evTableGetModelo(tabGraPers, 'dirc_memo'),
        is_upper: 1,
        label: 'Ubicación',
      },


      tel_area1: { ...evTableGetModelo(tabGraPerstel, 'tel_area'), can_null: 1, width: 4, label: 'Móvil-Area' },
      tel_nro1: { ...evTableGetModelo(tabGraPerstel, 'tel_nro'), can_null: 1, width: 8, label: 'Móvil-Nro.' },

      tel_area2: { ...evTableGetModelo(tabGraPerstel, 'tel_area'), can_null: 1, width: 4, label: 'Fijo-Area' },
      tel_nro2: { ...evTableGetModelo(tabGraPerstel, 'tel_nro'), can_null: 1, width: 8, label: 'Fijo-Nro.' },

    }


    const formMail = new EvForm({})
    formMail.initAll({modelos: modelosEmail})

    const form = new EvForm({})
    form.initAll({modelos: modelos})
    return {
      submiting: false,
      idShow1: 'formMail', // 'formMail', 'form'  
      okCF: 'S', // Es cons. final? S/N
      // formMail: evForm(modelosEmail),
      // form: evForm(modelos),
      formMail,
      form,
    }
  },
  computed: {
    prov () {
      return this.form.data.prov
    },
    locLovParams () {
      return { _flt: `gra_loc.est = 'A' and gra_loc.prov = ${evDbS(this.form.data.prov, this.form.modelos.prov)}` }
    },
  },
  methods: {
    onChangeOkCF () {
      // console.log(['onChangeOkCF: this.okCF', this.okCF])
      if (this.okCF == 'S') {
        this.form.fieldSetData('tiva', 1)
        this.form.fieldSetData('cuit', null)
      } else {
        this.form.fieldSetData('tiva', 2)
        this.form.fieldSetData('dni', null)
        this.form.fieldSetData('sexo', null)
      }
    },
    vldDniNull (data) {
      if (this.okCF === 'S' && data === null) {
        return 'No puede ser nulo'
      }
    },
    vldCuitNull (data) {
      if (this.okCF === 'N' && data === null) {
        return 'No puede ser nulo'
      }
    },

    resetPasswd () {
      this.submiting = true
      evAxio({
        vm: this,
        url: `/${this.vmApp.$root.evSessionId}/login/api/resetPasswd`,
        params: {
          _token: this.$root.evToken,
          email: this.formMail.data.email,
        },
        nvlError: 0
      })
        .then((data) => {
          if (data.error_nro === 0) {
            this.$root.$evModal.showInfo(`Se envió una nueva contraseña al correo electrónico "${this.formMail.data.email}"`)
            // this.$router.replace('/login')
            this.$router.replace({ path: '/login', query: { nextUrl: '/' } })

          }
        })
        .finally(() => { this.submiting = false })

    },

    formMailVld () {
      if (!this.formMail.formVld()) {
        // this.form.fieldFocus('email', this.$refs)
        this.formMail.errorFocus(this.$refs)
        return
      }

      this.submiting = true
      evAxio({
        vm: this,
        url: `/${this.vmApp.$root.evSessionId}/login/api/vldUserEmail`,
        params: {
          email: this.formMail.data.email,
        },
        nvlError: 1
      })
        .then((data) => {
          if (data.error_nro === 0) {
            if (data.user) {
              this.$root.$evModal.showConf(`
              El email "${this.formMail.data.email}" ya esta siendo usado por el usuario "${data.user}-${data.userDes}". 
              Desea que le enviemos una nueva clave de acceso a su email?
              `
              ).then(v => !v || this.resetPasswd())
            } else {
              this.idShow1 = 'form'
              this.form.formReset()
              this.form.formFocus(this.$refs)
            }
          } else {
            this.formMail.formSetErrors(data.errors)
            this.formMail.errorFocus(this.$refs)
          }
        })
        .finally(() => { this.submiting = false })
    },

    formVld () {
      // console.log(['formVld()'])
      if (!this.form.formVld()) {
        // this.form.fieldFocus('email', this.$refs)
        this.form.errorFocus(this.$refs)
        return
      }

      if (!this.form.data.tel_nro1 && !this.form.data.tel_nro2) {
        this.form.fieldSetError('tel_nro1', 'Debe ingresar por lo menos un número de teléfono.')
        this.form.fieldFocus('tel_nro1', this.$refs)
        return
      }
      if (this.form.data.tel_nro1 && !this.form.data.tel_area1) {
        this.form.fieldSetError('tel_area1', 'Debe ingresar Area.')
        this.form.fieldFocus('tel_area1', this.$refs)
        return
      }
      if (this.form.data.tel_nro2 && !this.form.data.tel_area2) {
        this.form.fieldSetError('tel_area2', 'Debe ingresar Area.')
        this.form.fieldFocus('tel_area2', this.$refs)
        return
      }



      const params = {
        email: this.formMail.data.email,
        ...this.form.data
      }
      if (params.tel_nro1) {
        params.tel_nro1 = `15${params.tel_nro1}`
      }

      this.submiting = true
      evAxio({
        vm: this,
        url: `/${this.vmApp.$root.evSessionId}/login/api/createUser`,
        params: params,
        nvlError: 1
      })
        .then((data) => {
          if (data.error_nro === 0) {
            this.$root.$evModal.showInfo(`Ya se registró. Recibirá un e-mail en "${this.formMail.data.email}" con su clave de acceso. Si no lo ve en su bandeja de entrada, recuerde revisar su carpeta de spam.`)
            // this.$router.replace('/login')
            this.$router.replace({ path: '/login', query: { nextUrl: '/' } })

          } else {
            this.form.formSetErrors(data.errors)
            this.form.errorFocus(this.$refs)
          }
        })
        .finally(() => { this.submiting = false })
    },


  }

}
</script>
