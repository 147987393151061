/* ev-modelo.js
*  Ruben Valle
*  Ver. 1.0: 01-08-2018
*
*  21-12-2018:
*  Se agrego validacion de range[]
*
*  13-08-2019:
*  Se agrego evVldRule() y evForm()
*
*  12-03-2020:
*  Se agrego form.values para poder usar v-model en <evu-field-text>
*  para evitar error que se producia por que no siempre se dispara el evento change.
*  02-06-2020:
*  Se agrego evNow() y evToday()
*  Ahora usamos moment.utc() en lugar de moment() para evitar problemass con las apis
*
*  27-10-2020
*  Se agrego: evParserCantCod()
*
*  16-12-2021
*  Se creo evIsEmpty() p/ dejar se usar la libreria validate.js

*  ==========================================================
*  Funciones relacionadas con el manejo de "modelos" para la edicion y validacion de "fields" en "forms"
*
  Ejemplos de modelos
  -------------------
    modelos: {
      zona: {
        label: 'Codigo de Zona',
        hint: 'Ingrese un nro. entero',
        placeholder: 'Nro. ?',
        type: 'evN',
        width: 6,
        can_null: 0,
        vlds: [evVldCuit, this.vldZona]
        },

      prec: {
          label: 'Precio',
          type: 'evF',
          width: 12,
          prec: 2,
          default: 120.45,
        },

      fecha: {
          label: 'Fecha',
          type: 'evD',
          width: 10,
          range: ['2019-01-01', '2019-12-31']
        },

      des: {
          label: 'Descripcion',
          type: 'evC',
          width: 40,
          is_upper: true,
      },

      memo: {
        label: 'Memo',
        type: 'evC',
        width: 2000,
        view_heigth: 10,
      },

      sexo: {
        label: 'Sexo',
        width: 1,
        type: 'evC',
        default: 'F',
        options: [
          {value: 'M', text: 'Masculino'},
          {value: 'F', text: 'Femenino'}
        ],
        fieldType: 'evu-radio'
        fieldAttr: {class: "mx-auto", subheader: "Ingrese Rango:"}
      }
    },

*/

// 15-12-2021: Dejamos de usar validate
// import validate from 'validate.js'

import moment from "moment";

// Definimos clases de error propias
// ===========================================
export class EV_ERROR extends Error {
  // eslint-disable-next-line
  constructor(message) {
    super(message);
    this.name = "EV_ERROR";
  }
}

export class EV_FALSE extends Error {
  // eslint-disable-next-line
  constructor(message) {
    super(message);
    this.name = "EV_FALSE";
  }
}

export const evIsEmpty = (value) => {
  // return validate.isEmpty(value)
  return value === "" || value === null || value === undefined;
};

// Convierte a Float y redondea
// ==================================================================
export const evRound = (value, prec) => {
  return parseFloat(parseFloat(value).toFixed(prec));
};

// ==================================================================
export const evNow = () => {
  return moment.utc(moment().format("YYYY-MM-DD HH:mm:ss"));
};

// ==================================================================
export const evToday = () => {
  return moment.utc(moment().format("YYYY-MM-DD"));
};

/*
// Formatea p/ mostrar en <evu-data-table>
export const evTdFormatter = (value, modelo) => {
    if (modelo.type === 'evD' || modelo.type === 'evTS') {
        return evFormatter(evParser(value, modelo), modelo)
    }
    return value
}
*/

// evNumberFormat[0].format(123456.789) -> '123.456'
// evNumberFormat[2].format(123456.789) -> '123.456,78'
// evNumberFormat[3].format(123456.789) -> '123.456,789'
export const evNumberFormat = {
  0: Intl.NumberFormat("es-ES", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }),
  2: Intl.NumberFormat("es-ES", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
  3: Intl.NumberFormat("es-ES", {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  }),
  6: Intl.NumberFormat("es-ES", {
    minimumFractionDigits: 6,
    maximumFractionDigits: 6,
  }),
};

// Recibe "data/value" y retorna "value"
export const evValue = (data, modelo) => {
  return evFormatter(evParser(data, modelo), modelo);
};

// Recibe "data" y lo retona como String  segun "modelo"
// Ej: evFormatter(12.347, {type: 'evF', prec: 2}) -> '12.35'
// ==================================================================
export const evFormatter = (data, modelo) => {
  // console.log(['evFormatter() recibe value=', value])
  let value;
  if (evIsEmpty(data)) {
    value = "";
  } else if (moment.isMoment(data)) {
    value = data.format(
      modelo.type === "evD" ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm:ss"
    );
    // 18-10-2021
  } else if (modelo.type === "evJS") {
    // a evJS no lo tocamos
    value = data;
    // } else if (!validate.isString(data)) {
  } else if (typeof data !== "string") {
    value = String(data);
  } else {
    value = data;
  }
  // console.log([this.evid, 'evFormatter() entrega value=', value])
  return value;
};

// P/ formatear celdas en <evu-data-table>
// ==================================================================
export const evTdFormatter = (data, modelo) => {
  if (data === null || data === undefined) {
    return null;
  }
  if (modelo.type === "evD" || modelo.type === "evTS") {
    return evValue(data, modelo);
  }
  if (modelo.type === "evF") {
    return evNumberFormat[modelo.prec].format(data);
  }
  return data;
};

// Recibe un  "value" como un String formateado segun "modelo" y retorna un "data"
// Ej: evParser('12.347', {type: 'evF', prec: 2}) -> 12.35 (NUmeric)
// Ej: evParser('2017-10-31', {type: 'evD'}) -> moment()
// ==================================================================
export const evParser = (value, modelo) => {
  // console.log(['evParser() value=', value, 'modelo=', modelo])
  var data;
  if (evIsEmpty(value)) {
    data = null;
  } else if (modelo.type === "evN" || modelo.type === "evL") {
    // data = validate.isInteger(value) ? value : parseInt(value)
    data = Number.isInteger(value) ? value : parseInt(value);
    if (isNaN(data)) {
      data = null;
    }
  } else if (modelo.type === "evF") {
    data = evRound(value, modelo.prec || 2);
    if (isNaN(data)) {
      data = null;
    }
    /*
        data = validate.isNumber(value) ? value : parseFloat(value)
        if (isNaN(data)) {
          data = null
        } else {
          data = data.toFixed(modelo.prec || 2) // Redondeo
          data = parseFloat(data)
        }
        */
  } else if (modelo.type === "evD") {
    // data = moment.isMoment(value) ? value : moment(value, ['DD/MM/YYYY', 'YYYY-MM-DD'])
    data = moment.isMoment(value)
      ? value
      : moment.utc(value, ["DD/MM/YYYY", "YYYY-MM-DD"]);
    if (!data.isValid()) {
      data = null;
    }
  } else if (modelo.type === "evTS") {
    // data = moment.isMoment(value) ? value : moment(value, ['DD/MM/YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss'])
    data = moment.isMoment(value)
      ? value
      : moment.utc(value, ["DD/MM/YYYY HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]);
    if (!data.isValid()) {
      data = null;
    }
    // 18-10-2021
    // A evJS no lo tocamos
    // } else if (modelo.type === 'evC' || modelo.type === 'evHM' || modelo.type === 'evJS') {
  } else if (modelo.type === "evC" || modelo.type === "evHM") {
    // data = validate.isString(value) ? value : String(value)
    data = typeof value === "string" ? value : String(value);
    if (!modelo.view_heigth) {
      data = data.trim();
    }
    if (modelo.is_lower) {
      data = data.toLowerCase();
    }
    if (modelo.is_upper) {
      data = data.toUpperCase();
    }
    // 18-10-2021
  } else if (modelo.type === "evJS") {
    data = value;
  } else {
    throw new EV_ERROR('modelo.type = "' + modelo.type + '" no soportado.');
  }
  if (data === "") {
    data = null;
  }
  // console.log(['evParser() value=', value, 'retornamos data=', data])
  return data;
};

// Ej: Ver: ccd/faci
// evParserCantCod('345')  => {cod: 345, cant: null, prec: null}
// evParserCantCod('1.5 * 345')  => {cod: 345, cant: 1.5, prec: null}
// evParserCantCod('1.5 * 345 * 2.50')  => {cod: 345, cant: 1.5, prec: 2.50}
// ==========================================================================
export const evParserCantCod = (value) => {
  // console.log(['evParser() value=', value, 'modelo=', modelo])
  let cod;
  let cant = null;
  let prec = null;
  const lst = (value || "").split("*");
  if (lst.length > 2) {
    cant = evRound(lst[0], 3);
    cod = parseInt(lst[1]);
    prec = evRound(lst[2], 3);
  } else if (lst.length > 1) {
    cant = evRound(lst[0], 3);
    cod = parseInt(lst[1]);
  } else {
    cod = parseInt(lst[0]);
  }
  if (isNaN(cod) || isNaN(cant) || isNaN(prec)) {
    cod = null;
    cant = null;
    prec = null;
  }
  return { cod, cant, prec };
};

// Ej:
//  return { _flt: `gra_loc.est = 'A' and gra_loc.prov=${evDbS(this.prov)` }
export const evDbS = (value, modelo) => {
  // console.log(['evFormatter() recibe value=', value])
  if (evIsEmpty(value)) {
    return "null";
  } else if (modelo.type === "evN") {
    return String(value);
  } else if (moment.isMoment(value)) {
    return `'${value.format(
      modelo.type === "evD" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss"
    )}`;
  }
  return `'${String(value)}'`;
};

// Valida el "data" segun las restricciones especificadas en el modelo
// Return mensaje de error or ''
// ==================================================================
export const evVldModelo = (data, modelo) => {
  let msg = evVldMeta(data, modelo);
  if (!msg && modelo.vlds) {
    // OjO: Las funciones "vld()" se ejecutan en modo sincronico
    for (let vld of modelo.vlds) {
      msg = vld(data, modelo);
      if (msg) {
        return msg;
      }
    }
  }
  return msg;
};

// Valida el "data" segun las restricciones especificadas en la metainformacion del modelo
// Return mensaje de error or ''
// ==================================================================
export const evVldMeta = (data, modelo) => {
  // console.log(['evVldMeta()', 'data=', data, 'modelo=', modelo])

  if (evIsEmpty(data)) {
    if (modelo.can_null) {
      return "";
    }
    return "No puede ser vacio";
  }

  // range: [1,5]
  // range: [null, 5]
  // range: [0, null]
  if (
    modelo.range &&
    modelo.range[0] !== null &&
    data < evParser(modelo.range[0], modelo)
  ) {
    return `Debe ser >= "${evValue(modelo.range[0], modelo)}"`;
  }
  if (
    modelo.range &&
    modelo.range[1] !== null &&
    data > evParser(modelo.range[1], modelo)
  ) {
    return `Debe ser <= "${evValue(modelo.range[1], modelo)}"`;
  }

  // if (modelo.is_email && !/.+@findErrort(data)) {
  //    return 'e-Mail invalido.'findError
  // }
  if (
    modelo.is_email &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      data
    )
  ) {
    return "e-Mail invalido.";
  }

  return "";
};
