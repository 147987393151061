<template>
  <v-container fluid>
    <v-card
      color="grey lighten-4"
      :disabled="submiting"
      :loading="submiting"
      v-show="localDicPedido.ped_next_func === 'END'"
      max-width="500"
      class="mx-auto"
    >

      <v-container class="text-center">
        <v-card-title>
          Pedido Nro. {{ localDicPedido.ped_ni }}
        </v-card-title>
        <v-avatar
          color="success"
          size="50"
          class="ml-auto"
        >
          <v-icon color="white">
            mdi-check-bold
          </v-icon>
        </v-avatar>
      </v-container>

      <v-card-text>
        Su pedido se asentó con éxito.
        <br />
        Procederemos a prepararlo y nos comunicaremos con usted para confirmarle el importe del mismo,
        fecha y hora estimada de entrega.
        <br />
        Muchas gracias por su compra.
      </v-card-text>

      <v-container class="text-center">
        <v-btn
          text
          color="primary"
          to="/"
        >Cerrar</v-btn>
      </v-container>
    </v-card>

  </v-container>
</template>

<script>
import { evAxio } from '@/evlib/utils/ev-axios'
export default {
  name: "fin-confirmacion",
  components: {
  },
  data () {
    return {
      submiting: false,
      localDicPedido: {},

    }
  },

  methods: {

    nextFuncProcesa () {
      // eslint-disable-next-line no-console
      // console.log(['fin-confirmacion.nextFuncProcesa(), this.localDicPedido=', this.localDicPedido])
      if (this.localDicPedido.ped_next_func === 'COB') {
        this.nextFuncCob()
      } else if (this.localDicPedido.ped_next_func === 'END') {
        this.nextFuncEnd()
      } else if (this.localDicPedido.ped_next_func === 'DESCARTAR') {
        this.nextFuncDescartar()
      } else {
        this.$root.$evModal.showError(
          `Error: ped_next_func="${this.localDicPedido.ped_next_func}" no soportado.`
        )
      }
    },

    nextFuncDescartar () {
      // eslint-disable-next-line no-console
      // console.log(['fin-confirmacion.nextFuncDescarta(), this.localDicPedido=', this.localDicPedido])

      this.$root.$evModal.showError(this.localDicPedido.ped_next_func_msg)
        .then(() => {
          // Reseteo para que la proxima vez que ingrese no vuelva a informar de este ped_ni.
          // this.$store.commit('SET_DIC_PEDIDO', {})
          this.$store.dispatch('cmdVaciarCarrito')


          // Redirijo al inicio
          this.$router.replace('/')
        })
    },


    nextFuncEnd () {
      // eslint-disable-next-line no-console
      // console.log(['fin-confirmacion.nextFuncEnd(), this.localDicPedido=', this.localDicPedido])
      // this.$store.commit('SET_DIC_PEDIDO', {})
      this.$store.dispatch('cmdVaciarCarrito')
    },

    // Gestiona cobranza del pedido
    nextFuncCob () {
      // eslint-disable-next-line no-console
      // console.log(['fin-confirmacion.nextFuncCob(), this.localDicPedido=', this.localDicPedido])

      this.$root.$evModal.showWait(`Gestionando cobranza del pedido nro. ${this.localDicPedido.ped_ni}`)
      evAxio({
        vm: this,
        url: '/ccd_cliente/tienda/api/refreshPedido',
        params: {
          _token: this.$root.evToken,
          ped_ni: this.localDicPedido.ped_ni
        },
        nvlError: 0
      })
        .then((data) => {

          // eslint-disable-next-line no-console
          // console.log(['fin-confirmacion.nextFuncCob().then, data=', data])


          this.localDicPedido = data.dicPedido

          this.$store.commit(
            "SET_DIC_PEDIDO",
            {
              ped_ni: this.localDicPedido.ped_ni,
              ped_next_func: this.localDicPedido.ped_next_func,
              ped_next_func_msg: this.localDicPedido.ped_next_func_msg
            }
          )


          if (this.localDicPedido.ped_next_func !== 'COB') {
            this.nextFuncProcesa()
          }

          // eslint-disable-next-line no-console
          // console.log(['this.localDicPedido.mpDataPreference=', this.localDicPedido.mpDataPreference])
          if (this.localDicPedido.mpDataPreference) {
            // Redirigimos para registrar el pago en Mercado Pago
            window.open(this.localDicPedido.mpDataPreference.init_point)
            return
          }
        })
        .finally(() => {
          this.$root.$evModal.hideWait()
        })
    }

  },

  beforeRouteEnter (to, from, next) {
    // eslint-disable-next-line no-console
    // console.log(['fin-confirmacion.beforeRouteEnter'])
    next(vm => {

      vm.localDicPedido = vm.$store.state.dicPedido

      // eslint-disable-next-line no-console
      // console.log(['fin-confirmacion``.beforeRouteEnter.next', vm, 'vm.localDicPedido=', vm.localDicPedido])

      if (vm.localDicPedido.ped_ni) {
        vm.nextFuncProcesa()
      } else {
        vm.$router.replace('/')
      }

    })
  }

}
</script>
