<template>
  <v-select
    ref="field"
    v-bind="localAttrs"
    :value="data"
    :errorMessages="error"
    :loading="localLoading"
    @input="onInput"
    @change="onChange"
  >
  </v-select>
</template>


<script>
/**
 * evu-field-select.vue
 * Ruben Valle
 * 17-08-2019
 * Wraper que da soporte de "modelo" al componente <v-select>.
 *
  * Ejemplo
 * --------------------------------------------------
 *  <evu-field-select
 *     field="prov"
 *     :form="form"
 *     lovQueryType="mounted"
 *  ></evu-field-select>
 * 
 * En "modelo.options" residen los "items" a seleccionar. 
 * "modelo.opcions": puede actualizarse segun el parametro "lovQueryType"
 * lovQueryType:
 * null => "modelo.opcions" permanece inalterable.
 * mounted => "modelo.opcions" se carga desde el server en mounted(). 
 * sync => Indem a "mounted" (Ver <evu-field-autocomplete>)
 */
import axios from 'axios'

export default {
  name: 'evu-field-select',
  inheritAttrs: false,
  props: {
    form: { type: Object, required: true }, //  form = evForm(modelos)
    field: { type: String, required: true }, // Ej: 'cod', 'des', etc.
    lovUrl: { type: String }, // Ej: '/gra/public/api/lovTable/gra_loc/loc'
    lovParams: { type: Object }, // Ej: { _flt: "gra_loc.est='A' and gra_loc.prov=2" }
    lovQueryType: { type: String }  // null, 'mounted', 'sync'
  },
  data () {
    return {
      localLoading: false
    }
  },
  computed: {
    nextFocus () {
      return this.form.nextFocus
    },

    modelo () {
      return this.form.modelos[this.field]
    },
    data () {
      return this.form.data[this.field]
    },
    error () {
      return this.form.errors[this.field] || this.form.vldsError[this.field]
    },
    localAttrs () {
      return {
        tabindex: 1,  // P/ que navegue entre fields        
        clearable: true,
        label: this.modelo.label,
        required: !this.modelo.can_null,
        hint: this.modelo.hint,
        items: this.modelo.options,
        ...this.$attrs
      }
    },
    localLov () {
      return this.modelo.lov || {}
    },
    localLovUrl () {
      return this.lovUrl || this.localLov.url
    },
    localLovParams () {
      return this.lovParams || this.localLov.params || {}
    },
    localLovQueryType () {
      return this.lovQueryType || this.localLov.queryType
    }
  },
  watch: {
    nextFocus () {
      if (this.nextFocus === this.field) {
        this.form.fieldFocus(null)
        this.focus()
      }
    },
    localLovParams () {
      // eslint-disable-next-line
      // console.log([this.modelo.label, 'watch: localLovParams() v=', v])
      if (!this.localLoading && this.localLovQueryType === 'mounted') {
        this.queryLov('')
      }
    }
  },

  methods: {
    focus () {
      this.$refs.field.focus()
    },
    onInput (v) {
      // console.log(['YYYYYYYYYYYYYYYYYYYY  - evu-field-select.onImput(), v=', v])
      this.form.onInputField(this.field, v)
      this.$emit('input', v)
    },
    onChange (v) {
      // console.log(['ZZZZZZZZZZZZZZZZZZZZZ - evu-field-select.onChange(), v=', v])
      this.form.fieldSetData(this.field, v)
      this.form.fieldVldModelo(this.field)
      this.$emit('change', this.form.data[this.field])
    },
    queryLov (v, params) {
      this.localLoading = true
      axios.post(
        this.localLovUrl,
        {
          _token: this.$root.evToken,
          _search: `%${v || ''}`,
          ...(params || this.localLovParams)
        }
      )
        .then((response) => {
          if (response.data.error_nro === 0) {
            this.modelo.options = response.data.rows || response.data.options
          } else {
            this.$root.$evModal.showError(response.data.error_txt)
          }
        })
        .catch((error) => { this.$root.$evModal.showAxioError(error) })
        .finally(() => { this.localLoading = false })
    }
  },

  mounted () {
    // 12-04-2020: Ya no lo usamos p/ evitar "Error: Converting circular structure to JSON"
    // this.form.refs[this.field] = this

    if (this.localLovQueryType === 'mounted') {
      this.queryLov('')
    }
  }
}
</script>
