<template>
  <v-container>
    <v-row justify="center">
      <v-col
        cols="12"
        md="5"
      >
        <v-card :disabled="submiting">
          <v-toolbar
            color="primary"
            dark
          >
            <v-btn
              icon
              to="/"
            >
              <v-icon>mdi-arrow-left</v-icon>

            </v-btn>

            <v-toolbar-title>Olvidó su Contraseña?</v-toolbar-title>
          </v-toolbar>

          <form @submit.prevent="formVld">

            <v-container>
              <p class="subtitle-1 grey--text text--darken-1">
                Por favor introduzca la dirección de email que utilizó para registrarse y le enviaremos su nueva contraseña.
              </p>
              <evu-field-text
                ref="email"
                field="email"
                :form="form"
                autofocus
                type="email"
              >
              </evu-field-text>

            </v-container>

          </form>

          <v-container class="text-center">

            <v-btn
              @click="formVld"
              color="primary"
              :loading="submiting"
              class="mx-2"
            >Recuperar</v-btn>

          </v-container>
        </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>


<script>
import { evAxio } from '@/evlib/utils/ev-axios'
import { EvForm } from '@/evlib/utils/ev-form'
import evuFieldText from '@/evlib/evu-components/evu-field-text'
export default {
  name: 'ev-reset-password',
  components: {
    evuFieldText,
  },
  inject: [
    'vmApp'
  ],
  data () {
    const form = new EvForm({})
    form.initAll({
      // Metadatos de los fields
      modelos: {
        email: {
          label: 'Correo Electrónico',
          type: 'evC',
          width: 80,
          can_null: 0,
          is_lower: 1,
          is_email: 1,

        },
      }

    })

    return {
      submiting: false,
      form
    }
  },
  /* eslint-disable */
  methods: {
    formVld () {
      if (!this.form.formVld()) {
        // this.form.fieldFocus('email', this.$refs)
        this.form.errorFocus(this.$refs)
        return
      }

      this.submiting = true
      evAxio({
        vm: this,
        url: `/${this.vmApp.$root.evSessionId}/login/api/resetPasswd`,
        params: {
          _token: this.$root.evToken,
          email: this.form.data.email,
        },
        nvlError: 1
      })
        .then((data) => {
          if (data.error_nro === 0) {
            this.$root.$evModal.showInfo(`Se envió una nueva contraseña al correo electrónico "${this.form.data.email}"`)
            // this.$router.replace('/login')
            this.$router.replace({ path: '/login', query: { nextUrl: '/' } })

          } else {
            this.form.formSetErrors(data.errors)
            this.form.errorFocus(this.$refs)
          }
        })
        .finally(() => { this.submiting = false })
    },

  }

}
</script>
