<template>
  <v-carousel class="hidden-sm-and-down">
    <v-carousel-item
      v-for="(file_ni, i) in lstFileNi"
      :key="i"
      :src="`/drive/file/index/0/${file_ni}`"
      reverse-transition="fade"
      transition="fade"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "t-carousel",
  props: {
    lstFileNi: { type: Array, required: true } // [123, 456]
  }
}
</script>

<style lang="scss" scoped>
.v-carousel {
  height: 440px !important;

  @media (max-width: 600px) {
    height: 280px !important;
  }
}
</style>