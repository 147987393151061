<template>
  <v-container fluid>
    <depo-select></depo-select>
    <plu-card-edit></plu-card-edit>

    <lst-carousel
      :lstCarousel="lstCarousel"
      v-if="lstCarousel.length"
    />

    <div
      v-for="(row, i) in tiendaConf.rows"
      :key="i"
    >
      <div
        :is="row.comp"
        v-bind="row"
      />

    </div>
  </v-container>
</template>

<script>
import depoSelect from "@/components/depo-select"
import pluCardEdit from "@/components/plu-card-edit"
import lstCarousel from "@/components/lst-carousel";
import tCarousel from "@/components/t-carousel";
import tPluSlide from "@/components/t-plu-slide";

/* eslint-disable */

export default {
  name: "inicio",
  components: {
    depoSelect,
    pluCardEdit,
    lstCarousel,
    tCarousel,
    tPluSlide,
  },


  inject: ["vmApp"],
  computed: {
    lstCarousel () {
      return this.vmApp.appLstCarousel
    },
    tiendaConf () {
      return this.vmApp.tiendaConf || {}
    }
  },

  beforeRouteEnter (to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-console
      // console.log(["inicio.beforeRouteEnter() f.name=", from.name, "t.name=", to.name])

      // 26-11-2021
      // Para que no se cierre el menu al seleccionar categoria en los mpbiles
      if (from.name === 'plu-main' && vm.$vuetify.breakpoint.smAndDown) {
        vm.vmApp.drawerLeft = true
      }

      vm.vmApp.drawerLeftShow = 'categoria-menu'
    })

  },
  beforeDestroy () {
    // eslint-disable-next-line no-console
    // console.log(["pluMain.beforeDestroy()"]);

    this.vmApp.drawerLeftShow = 'categoria-menu'

  },

}
</script>
