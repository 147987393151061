/* prs-cant.js
Funciones utiles para trabajar con "lstPrsCant" para productos que se venden
por Pieza o 1/2 Pieza y se cobra por Kg.
*/

import { evRound, evParser } from "@/evlib/utils/ev-modelo";

// dicPlu.web_lst_prs = '103,230,200'
// state.optionsPrs = [{prs, des, ....}, ...]
//
// Recibe un String de los codigos de presentacion que soporta un plu,
// y en base a las opciones de presentacion retorna una lista
// lstPrsCant = [{prs, des, web_coef, web_coef_calc, prs_cant, cant},..]
export const parserWebLstPrs = (webLstPrs, optionsPrs) => {
  const lstPrsCant = webLstPrs
    .split(",")
    .map((prs) => {
      return {
        ...optionsPrs.find((item) => item.prs == prs),
        prs_cant: 0, // Cant. de Piezas o Blister
        cant: 0, // Cant. de Kg equivalente
      };
    })
    .filter((item) => item.prs);
  return lstPrsCant;
};

// Para cada item de la lstPrsCant cacula: cant = prs_cant * ...
export const calcLstPrsCant = (lstPrsCant, pluPeso) => {
  return lstPrsCant.map((item) => {
    let cant;
    let prs_cant = evParser(item.prs_cant, { type: "evN" });
    if (prs_cant <= 0) {
      prs_cant = 0;
    }

    if (item.web_coef_calc === "P") {
      cant = evRound(prs_cant * item.web_coef * pluPeso, 3);
    } else {
      // item.web_coef_calc === "G" || item.web_coef_calc === "U"
      cant = evRound(prs_cant * item.web_coef, 3);
    }

    return {
      ...item,
      prs_cant,
      cant,
    };
  });
};

// Sumariza para obtener el total equivalente en Kg
export const sumLstPrsCant = (lstPrsCant) => {
  return evRound(
    lstPrsCant.reduce((accumulator, item) => accumulator + item.cant, 0),
    3
  );
};

// Actualiza newLstPrsCant.prs_cant = oldLstPrsCant.prs_cant
// Sirve para el inicio cuando se refrescan los renglones que quedaron
// guardados en el LocalStore
export const updateLstPrsCant = (newLstPrsCant, oldLstPrsCant, pluPeso) => {
  // lstPrsCant = [{prs, des, web_coef, web_coef_calc, prs_cant, cant},..]
  newLstPrsCant.forEach((n) => {
    const i = oldLstPrsCant.findIndex((o) => o.prs === n.prs);
    if (i < 0) {
      n.prs_cant = 0;
      n.cant = 0;
    } else {
      n.prs_cant = oldLstPrsCant[i].prs_cant;
    }
  });
  return calcLstPrsCant(newLstPrsCant, pluPeso);
};
