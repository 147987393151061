<template>
  <v-dialog
    :value="okDialog"
    max-width="500"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    persistent
  >
    <v-card
      flat
      tile
    >
      <v-toolbar
        dense
        color="secondary"
        dark
      >
        <v-btn
          icon
          @click="cmdClose"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title> Seleccione "Tipo de Entrega" </v-toolbar-title>
      </v-toolbar>

      <v-alert
        border="left"
        type="error"
        class="ma-2"
      >
        Para poder informar el stock de la sucursal más próxima a su zona, necesitamos que seleccione el <strong>Tipo de Entrega</strong> que desea usar.
      </v-alert>

      <fin-slc-repar-cod @input="onInput"></fin-slc-repar-cod>

    </v-card>
  </v-dialog>
</template>


<script>
import finSlcReparCod from "@/components/fin-slc-repar-cod"

export default {
  name: 'depo-select',
  components: {
    finSlcReparCod
  },
  inject: ['vmApp'],
  computed: {
    okDialog () {
      return this.$store.state.cmpEstadio === 'depoSelecting'
    },

  },
  methods: {
    cmdClose () {
      this.$store.dispatch('goMainSearching')
    },

    onInput () {
      // Vuelve a leer la conf. de la tienda para que 
      // muestre el stock del nuevo deposito seleccionado
      this.vmApp.getTiendaConf()

      this.$emit('select')
      this.cmdClose()
    }

  }
}
</script>
