import { render, staticRenderFns } from "./grpdep-card.vue?vue&type=template&id=35949c3f&scoped=true&"
import script from "./grpdep-card.vue?vue&type=script&lang=js&"
export * from "./grpdep-card.vue?vue&type=script&lang=js&"
import style0 from "./grpdep-card.vue?vue&type=style&index=0&id=35949c3f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35949c3f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VContainer,VList,VListItem,VListItemSubtitle,VListItemTitle})
