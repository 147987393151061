<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-credit-card-outline </v-icon>

      </v-btn>

    </template>

    <v-card
      tile
      flat
    >

      <v-toolbar tile>
        <v-list-item-avatar tile>
          <v-img :src="dicPlu.file_ni ? `/drive/file/thumbnail/0/${dicPlu.file_ni}` : ''"></v-img>
        </v-list-item-avatar>

        <v-spacer></v-spacer>

        <v-toolbar-title class="primary--text">
          {{ dicPlu.cod }} - {{dicPlu.web_des}}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="onClick"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="height: 100%">

        <v-list two-line>
          <template v-for="(fcb, i) in dicPlu.lst_web_fcb">

            <v-list-item
              :key="i"
              @click="onClick(fcb)"
            >
              <v-list-item-content>
                <v-list-item-subtitle>{{fcb.des}}</v-list-item-subtitle>
                <v-list-item-title class="d-flex">
                  <span>{{fcb.k_cuo}} x {{numberFormat(fcb.imp_cuo)}}</span>
                  <span class="ml-auto">$ {{numberFormat(fcb.tot)}} </span>
                </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>


<script>
import { evNumberFormat } from "@/evlib/utils/ev-modelo"
export default {
  name: "plu-html-det",
  props: {
    dicPlu: { type: Object, required: true },
  },
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    onClick () {
      this.dialog = false
    },
    numberFormat (n) {
      return evNumberFormat[2].format(n)
    }
  }
}
</script>

