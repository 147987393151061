<template>
  <v-container fluid>
    <v-btn
      icon
      to="/"
    >
      <v-icon class="primary--text">mdi-arrow-left</v-icon>

    </v-btn>
    <span class="text-h6 primary--text text--accent-2">Seleccione una Categoría</span>

    <div class="multcont">
      <div
        v-for="grpdep in $store.state.optionsGrpdep"
        :key="grpdep.grpdep"
        class="multcard"
      >
        <grpdep-card
          :grpdep="grpdep.grpdep"
          :des="grpdep.des"
        ></grpdep-card>

      </div>

    </div>
  </v-container>
</template>

<script>
import grpdepCard from "@/components/grpdep-card";

export default {
  name: "grpdep-menu",
  components: {
    grpdepCard,
  },
  inject: ["vmApp"],

  mounted () {
    // eslint-disable-next-line no-console
    // console.log(["grpdep-menu.mounted()"]);

    // 26-04-2021
    // -----v
    this.vmApp.drawerLeft = false
    // -----^
  },
  // beforeDestroy () {
  // eslint-disable-next-line no-console
  // console.log(["rppdep-menu.beforeDestroy()"]);
  // this.vmApp.drawerLeft = false
  // }


};
</script>


<style lang="scss" scoped>
.multcont {
  column-width: 300px;
  column-count: auto;
}

.multcard {
  break-inside: avoid;
}
</style>
