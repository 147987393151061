<template>
  <div>
    <v-text-field
      ref="field"
      v-bind="localAttrs"
      :value="form.values[field]"
      :errorMessages="error"
      @click:prepend-inner="() => $emit('click:prepend-inner')"
      @click:append="() => $emit('click:append')"
      @click:append-outer="() => $emit('click:append-outer')"
      @input="onInput"
      @change="onChange"
    >
    </v-text-field>
  </div>
</template>


<script>
/**
 * evu-field-text.vue
 * Ruben Valle
 * 17-08-2019
 * Wraper que da soporte de "modelo" al componente <v-text-field> .
 *
 * Ejemplo
 * --------------------------------------------------
 *  <evu-field-text
 *    field="cod"
 *    :form="form"
 *    outlined
 *  ></evu-field-text>
 */

export default {
  name: 'evu-field-text',
  inheritAttrs: false,
  props: {
    form: { type: Object, required: true }, //  form = evForm(modelos)
    field: { type: String, required: true }, // Ej: 'cod', 'des', etc.
  },
  computed: {
    nextFocus () {
      return this.form.nextFocus
    },
    modelo () {
      return this.form.modelos[this.field]
    },
    error () {
      return this.form.errors[this.field] || this.form.vldsError[this.field]
    },
    localAttrs () {
      const attrs = {
        tabindex: 1,  // P/ que navegue entre fields
        step: 'any',  // OjO hace falta p/ cuando type='evF'
        clearable: true,
        autocomplete: "off",
        type: (this.modelo.type === 'evN' || this.modelo.type === 'evL' || this.modelo.type === 'evF' ? 'number' : 'text'),
        label: this.modelo.label,
        required: !this.modelo.can_null,
        maxlength: this.modelo.width,
        counter: this.modelo.width,
        hint: this.modelo.hint,
        placeholder: this.modelo.type === 'evD' ? 'dd/mm/aaaa' : this.modelo.type === 'evTS' ? 'dd/mm/aaaa HH:MM:SS' : '',
        ...this.$attrs
      }
      // console.log('attrs=', attrs)
      return attrs
    }
  },
  watch: {
    nextFocus: {
      immediate: true,
      handler: 'handlerNextFocus'
    }
  },

  methods: {
    handlerNextFocus () {
      // console.log(['evu-field-text.handlerNextFocus()=', this.nextFocus, this.field])
      if (this.nextFocus === this.field) {
        this.form.fieldFocus(null)
        // this.focus()
        // 
        // OjO: En "tv/edit_tanda/tabedTanda" necesitamos $nextTick() p/ que tome bien el foco
        this.$nextTick(() => this.focus())
      }

    },
    /* eslint-disable */
    onInput (v) {
      // console.log(['YYYYYYYYYYYYYYYYYYYY  - evu-field-text.onImput(), v=', v])
      this.form.onInputField(this.field, v)
      this.$emit('input', v)

      /* 12-03-2020: Creo que ya no necesitamos llamar a this.onChange('')
      * -----------------------v
      // Cuando hacemos click en el boton CLEAR, "v-text-field" emite el evento 
      // "$event('input', null)" pero no emite el evento "change"
      if (v === null) {
        this.onChange('')
      }
      * -----------------------^
      */
    },
    onChange (v) {
      // console.log(['ZZZZZZZZZZZZZZZZZZZZZ - evu-field-text.onChange(), v=', v])
      this.form.fieldSetData(this.field, this.form.values[this.field])
      this.form.fieldVldModelo(this.field)
      this.$emit('change', this.form.data[this.field])
    },
    focus () {
      // console.log(['evu-field-text.focus(), this.field=', this.field, 'refs$=', this.$refs, 'refs$.field=', this.$refs['field']])
      // 
      // this.$refs.field.focus()
      // OjO: En "tv/edit_tanda/tabedTanda" necesitamos $nextTick() p/ que tome bien el foco
      this.$nextTick(() => this.$refs.field.focus())
    }
  },

  // mounted () {
  // console.log(['evu-field-text.mounted()', 'refs$.field=', this.$refs['field'], this.nextFocus, this.field])
  // this.handlerNextFocus()
  // }
}
</script>
