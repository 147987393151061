<template>
  <v-radio-group
    v-bind="localAttrs"
    :value="data"
    :errorMessages="error"
    @input="onInput"
    @change="onChange"
  >
    <template v-for="(item, i) in localItems">

      <v-radio
        tabindex="1"
        :key="i"
        :label="item.text"
        :value="item.value"
      ></v-radio>

    </template>
  </v-radio-group>
</template>


<script>
/**
 * evu-field-radio.vue
 * Ruben Valle
 * 03-12-2019
 * Wraper que da soporte de "modelo" al componente <v-radio>.
 *
 * Ejemplo
 * --------------------------------------------------
 *  <evu-field-radio
 *     field="sexo"
 *     :form="form"
 *     :items="[{value: 'M', text: 'Masculino'}, {value: 'F', text: 'Femenino'}]" 
 *  ></evu-field-select>
 */
export default {
  name: 'evu-field-radio',
  inheritAttrs: false,
  props: {
    form: { type: Object, required: true }, //  form = evForm(modelos)
    field: { type: String, required: true }, // Ej: 'cod', 'des', etc.
    items: { type: Array }
  },
  computed: {
    nextFocus () {
      return this.form.nextFocus
    },
    modelo () {
      return this.form.modelos[this.field]
    },
    data () {
      return this.form.data[this.field]
    },
    error () {
      return this.form.errors[this.field] || this.form.vldsError[this.field]
    },
    localItems () {
      return this.items || this.modelo.options || []
    },
    localAttrs () {
      return {
        tabindex: 1,  // P/ que navegue entre fields
        clearable: true,
        label: this.modelo.label,
        required: !this.modelo.can_null,
        hint: this.modelo.hint,
        // items: this.modelo.options,
        ...this.$attrs
      }
    },
  },
  watch: {
    nextFocus () {
      if (this.nextFocus === this.field) {
        this.form.fieldFocus(null)
        this.focus()
      }
    },
  },

  methods: {
    /* eslint-disable */
    onInput (v) {
      // console.log(['YYYYYYYYYYYYYYYYYYYY  - evu-field-radio.onImput(), v=', v])
      this.form.errors[this.field] = null
      this.form.vldsError[this.field] = null
      this.$emit('input', v)
    },
    onChange (v) {
      // console.log(['ZZZZZZZZZZZZZZZZZZZZZ - evu-field-radio.onChange(), v=', v])
      this.form.fieldSetData(this.field, v)
      this.form.fieldVldModelo(this.field)
      this.$emit('change', v)
    },
    focus () {
      // console.log(['wwwwwwwwwwwww - evu-field-radio.focus()'])
      // this.$refs.field.focus()
      // Este artilugio lo deducimos a prueba y error.
      // this.$refs.field.$el.children[0].children[0].focus()
    },
  },

  /*
  mounted () {
    // 12-04-2020: Ya no lo usamos p/ evitar "Error: Converting circular structure to JSON"
    // this.form.refs[this.field] = this
  }
  */
}
</script>
